// EmpleadoItem.jsx

import React from 'react';
import './EmpleadoItem.css';

const EmpleadoItem = ({ empleado, handleEditarEmpleado, handleEliminarEmpleado }) => {
  return (
    <li className="empleado-item">
      <div className="empleado-info">
        <p><strong>Nombre:</strong> {empleado.nombre} {empleado.apellido}</p>
        <p><strong>Correo:</strong> {empleado.correo}</p>
        <p><strong>Teléfono:</strong> {empleado.telefono || 'No especificado'}</p>
      </div>
      <div className="empleado-actions">
        <button className="btn-editar" onClick={handleEditarEmpleado}>Editar</button>
        <button className="btn-eliminar" onClick={handleEliminarEmpleado}>Eliminar</button>
      </div>
    </li>
  );
};

export default EmpleadoItem;
