import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SidebarDerecho from '../../Sidebars/SidebarDerecho';
import DashboardContent from '../../DashboardContent/DashboardContent';
import { fetchPuestos } from '../../Servicios/serviciosService';
import './ArrendadorDashboard.css';

const ArrendadorDashboard = () => {
  const [puestos, setPuestos] = useState([]);
  const navigate = useNavigate();

  const loadPuestos = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      const data = await fetchPuestos(token);
      setPuestos(data);
    } catch (error) {
      console.error('Error al cargar los puestos:', error);
    }
  };

  useEffect(() => {
    loadPuestos();
  }, []);

  return (
    <div className="dashboard-container">
      <div className="sidebar-izquierdo">
        {/* Placeholder para el sidebar izquierdo */}
      </div>

      <div className="dashboard-content">
        <header className="dashboard-header">
          <h2>Panel Principal</h2>
        </header>

        <DashboardContent puestos={puestos} />
      </div>

      {/* Elimina el div con la clase 'sidebar-derecho' */}
      <SidebarDerecho />
    </div>
  );
};

export default ArrendadorDashboard;
