import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './CalendarioReservas.css';
import { fetchServicios } from '../Servicios/serviciosService';

const CalendarioReservas = ({ puesto, token }) => {
  const [reservas, setReservas] = useState([]);
  const [reservasMes, setReservasMes] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [activeStartDate, setActiveStartDate] = useState(new Date());
  const [selectedServicio, setSelectedServicio] = useState(null);
  const [startTime, setStartTime] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [servicios, setServicios] = useState([]);
  const [loadingServicios, setLoadingServicios] = useState(false);
  const [clienteNombre, setClienteNombre] = useState('');
  const [clienteContacto, setClienteContacto] = useState('');
  const [reservaEnEdicion, setReservaEnEdicion] = useState(null);
  const [reservaExpandida, setReservaExpandida] = useState(null);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  // Definir la URL del backend
  const backendUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001/api/';

  // Array de fechas de feriados (en formato 'YYYY-MM-DD')
  const holidays = [
    '2023-12-25', // Navidad
    '2024-01-01', // Año Nuevo
    // Agrega más fechas de feriados aquí
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 60000); // Actualiza la hora actual cada minuto

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (puesto.id) fetchReservas();
  }, [puesto.id, selectedDate]);

  useEffect(() => {
    if (puesto.id) {
      const startDate = new Date(
        activeStartDate.getFullYear(),
        activeStartDate.getMonth(),
        1
      );
      const endDate = new Date(
        activeStartDate.getFullYear(),
        activeStartDate.getMonth() + 1,
        0
      );
      fetchReservasMes(startDate, endDate);
    }
  }, [puesto.id, activeStartDate]);

  const fetchReservas = async () => {
    const fechaSeleccionada = selectedDate.toISOString().split('T')[0];
    try {
      const response = await fetch(
        `${backendUrl}puestos/${puesto.id}/reservas?fecha=${fechaSeleccionada}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Error al obtener las reservas');
      }

      setReservas(data);
      setErrorMessage('');
    } catch (error) {
      console.error('Error al obtener las reservas:', error);
      setReservas([]);
      setErrorMessage('No hay reservas para este día.');
    }
  };

  const fetchReservasMes = async (startDate, endDate) => {
    try {
      const response = await fetch(
        `${backendUrl}puestos/${puesto.id}/reservas?startDate=${startDate
          .toISOString()
          .split('T')[0]}&endDate=${endDate.toISOString().split('T')[0]}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Error al obtener las reservas del mes');
      }

      setReservasMes(data);
    } catch (error) {
      console.error('Error al obtener las reservas del mes:', error);
      setReservasMes([]);
    }
  };

  const loadServicios = async () => {
    if (loadingServicios) return;
    setLoadingServicios(true);
    try {
      const data = await fetchServicios(token);
      setServicios(data);
    } catch (error) {
      console.error('Error al cargar los servicios:', error);
      setErrorMessage('Hubo un error al cargar los servicios.');
    } finally {
      setLoadingServicios(false);
    }
  };

  const handleServicioDropdownClick = () => {
    loadServicios();
  };

  const handleServicioChange = (event) => {
    const servicioId = parseInt(event.target.value, 10);
    const servicioSeleccionado = servicios.find(
      (servicio) => servicio.id === servicioId
    );
    setSelectedServicio(servicioSeleccionado);
  };

  const handleStartTimeChange = (event) => setStartTime(event.target.value);

  const handleClienteNombreChange = (event) =>
    setClienteNombre(event.target.value);

  const handleClienteContactoChange = (event) =>
    setClienteContacto(event.target.value);

  const handleReservar = async () => {
    if (!selectedServicio || !startTime) {
      setErrorMessage(
        'Selecciona un servicio e ingresa una hora de inicio válida.'
      );
      return;
    }

    const nuevaReserva = {
      trabajadorId: 1,
      fechaReserva: selectedDate.toISOString().split('T')[0],
      horaInicio: startTime,
      duracion: selectedServicio.duracion,
      clienteNombre: clienteNombre || 'Cliente Genérico',
      clienteContacto: clienteContacto || 'Sin Contacto',
      servicioId: selectedServicio.id,
      servicioDetalle: {
        id: selectedServicio.id,
        nombre_servicio: selectedServicio.nombre_servicio,
        costo: selectedServicio.costo,
        duracion: selectedServicio.duracion,
        descripcion: selectedServicio.descripcion || '',
      },
    };

    try {
      const response = await fetch(
        `${backendUrl}puestos/${puesto.id}/reservas`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(nuevaReserva),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        console.error('Error del servidor:', data);
        throw new Error(data.error || 'Error al crear la reserva');
      }

      fetchReservas();
      const startDate = new Date(
        activeStartDate.getFullYear(),
        activeStartDate.getMonth(),
        1
      );
      const endDate = new Date(
        activeStartDate.getFullYear(),
        activeStartDate.getMonth() + 1,
        0
      );
      fetchReservasMes(startDate, endDate);

      setStartTime('');
      setSelectedServicio(null);
      setClienteNombre('');
      setClienteContacto('');
      setErrorMessage('');
      alert('Reserva creada exitosamente');
    } catch (error) {
      console.error('Error al realizar la reserva:', error);
      setErrorMessage(
        'No se pudo realizar la reserva. Por favor, verifica los datos.'
      );
    }
  };

  const handleEditarReserva = (reserva) => {
    setReservaEnEdicion(reserva);
    setSelectedServicio(
      servicios.find((s) => s.id === reserva.servicio_id) || null
    );
    setStartTime(reserva.hora_inicio);
    setClienteNombre(reserva.cliente_nombre);
    setClienteContacto(reserva.cliente_contacto);
    setSelectedDate(new Date(reserva.fecha_reserva));
  };

  const handleActualizarReserva = async () => {
    if (selectedServicio && startTime && reservaEnEdicion) {
      const reservaActualizada = {
        trabajadorId: 1,
        fechaReserva: selectedDate.toISOString().split('T')[0],
        horaInicio: startTime,
        duracion: selectedServicio.duracion,
        clienteNombre: clienteNombre || 'Cliente Genérico',
        clienteContacto: clienteContacto || 'Sin Contacto',
        servicioId: selectedServicio.id,
        servicioDetalle: {
          id: selectedServicio.id,
          nombre_servicio: selectedServicio.nombre_servicio,
          costo: selectedServicio.costo,
          duracion: selectedServicio.duracion,
          descripcion: selectedServicio.descripcion || '',
        },
      };

      try {
        const response = await fetch(
          `${backendUrl}puestos/${puesto.id}/reservas/${reservaEnEdicion.id}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(reservaActualizada),
          }
        );
        const data = await response.json();
        if (!response.ok) {
          console.error('Error del servidor:', data);
          throw new Error(data.error || 'Error al actualizar la reserva');
        }

        fetchReservas();
        const startDate = new Date(
          activeStartDate.getFullYear(),
          activeStartDate.getMonth(),
          1
        );
        const endDate = new Date(
          activeStartDate.getFullYear(),
          activeStartDate.getMonth() + 1,
          0
        );
        fetchReservasMes(startDate, endDate);

        setStartTime('');
        setClienteNombre('');
        setClienteContacto('');
        setSelectedServicio(null);
        setReservaEnEdicion(null);
        setErrorMessage('');
        alert('Reserva actualizada exitosamente');
      } catch (error) {
        console.error('Error al actualizar la reserva:', error);
        setErrorMessage(
          'No se pudo actualizar la reserva. Por favor, verifica los datos.'
        );
      }
    } else {
      setErrorMessage(
        'Selecciona un servicio e ingresa una hora de inicio válida.'
      );
    }
  };

  const handleCancelarEdicion = () => {
    setReservaEnEdicion(null);
    setStartTime('');
    setClienteNombre('');
    setClienteContacto('');
    setSelectedServicio(null);
    setErrorMessage('');
  };

  const handleEliminarReserva = async (reservaId) => {
    try {
      const response = await fetch(
        `${backendUrl}puestos/${puesto.id}/reservas/${reservaId}`,
        {
          method: 'DELETE',
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.ok) {
        fetchReservas();
        const startDate = new Date(
          activeStartDate.getFullYear(),
          activeStartDate.getMonth(),
          1
        );
        const endDate = new Date(
          activeStartDate.getFullYear(),
          activeStartDate.getMonth() + 1,
          0
        );
        fetchReservasMes(startDate, endDate);
        alert('Reserva eliminada exitosamente');
      } else {
        alert('Error al eliminar la reserva');
      }
    } catch (error) {
      console.error('Error al eliminar la reserva:', error);
      alert('Hubo un error al eliminar la reserva.');
    }
  };

  const handleMarcarCompletada = async (reservaId) => {
    try {
      const response = await fetch(
        `${backendUrl}puestos/${puesto.id}/reservas/${reservaId}/estado`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ estado: 'completada' }),
        }
      );
      const data = await response.json();

      if (!response.ok)
        throw new Error(data.error || 'Error al actualizar el estado');

      fetchReservas();
      const startDate = new Date(
        activeStartDate.getFullYear(),
        activeStartDate.getMonth(),
        1
      );
      const endDate = new Date(
        activeStartDate.getFullYear(),
        activeStartDate.getMonth() + 1,
        0
      );
      fetchReservasMes(startDate, endDate);

      alert('Reserva marcada como completada');
    } catch (error) {
      console.error('Error al actualizar el estado de la reserva:', error);
      alert('No se pudo actualizar el estado de la reserva.');
    }
  };

  const parseTimeToDate = (dateString, timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    const date = new Date(dateString);
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  };

  const toggleReserva = (id) => {
    setReservaExpandida(reservaExpandida === id ? null : id);
  };

  // Función para asignar clases a los días del calendario
  const tileClassName = ({ date, view }) => {
    if (view === 'month') {
      const dateString = date.toISOString().split('T')[0];

      // Verificar si es un día feriado
      const isHoliday = holidays.includes(dateString);

      // Verificar si el día tiene reservas
      const isReserved = reservasMes.some((reserva) => {
        const reservaDateString = new Date(reserva.fecha_reserva)
          .toISOString()
          .split('T')[0];
        return reservaDateString === dateString;
      });

      const isSelected = dateString === selectedDate.toISOString().split('T')[0];
      const isToday = dateString === new Date().toISOString().split('T')[0];

      let className = '';

      if (isHoliday) className += ' react-calendar__tile--holiday';
      if (isReserved) className += ' react-calendar__tile--reserved';
      if (isSelected) className += ' react-calendar__tile--selected';
      if (isToday) className += ' react-calendar__tile--today';

      return className.trim();
    }
    return null;
  };

  return (
    <div className="calendario-reservas-container">
      {/* Calendario */}
      <div className="calendario">
        <h3>Calendario</h3>
        <Calendar
          onChange={(date) => setSelectedDate(date)}
          value={selectedDate}
          onActiveStartDateChange={({ activeStartDate }) =>
            setActiveStartDate(activeStartDate)
          }
          tileClassName={tileClassName}
        />
      </div>

      {/* Reservas del Día */}
      <div className="reservas">
        <h4>Reservas del día {selectedDate.toLocaleDateString()}:</h4>
        {reservas.length === 0 && <p>No hay reservas para este día.</p>}
        <ul>
          {reservas.map((reserva) => {
            const reservaInicio = parseTimeToDate(
              reserva.fecha_reserva,
              reserva.hora_inicio
            );
            const reservaFin = parseTimeToDate(
              reserva.fecha_reserva,
              reserva.hora_fin
            );

            const currentTime = new Date();

            const isCurrentReservation =
              currentTime >= reservaInicio && currentTime < reservaFin;

            return (
              <li
                key={reserva.id}
                className={`reserva-item ${
                  reserva.estado === 'completada' ? 'completada' : ''
                } ${isCurrentReservation ? 'reserva-actual' : ''}`}
              >
                <div
                  className="reserva-summary"
                  onClick={() => toggleReserva(reserva.id)}
                >
                  <div>
                    <strong>Servicio:</strong>{' '}
                    {reserva.servicio_detalle?.nombre_servicio ||
                      'No disponible'}
                  </div>
                  <div>
                    <strong>Hora Inicio:</strong> {reserva.hora_inicio}
                  </div>
                </div>
                {reservaExpandida === reserva.id && (
                  <div className="reserva-details">
                    <div>
                      <strong>Cliente:</strong> {reserva.cliente_nombre}
                    </div>
                    <div>
                      <strong>Contacto:</strong> {reserva.cliente_contacto}
                    </div>
                    <div>
                      <strong>Hora Fin:</strong> {reserva.hora_fin}
                    </div>
                    <div>
                      <strong>Total:</strong> $
                      {parseFloat(
                        reserva.servicio_detalle?.costo || 0
                      ).toLocaleString()}
                    </div>
                    <div className="reserva-actions">
                      {reserva.estado !== 'completada' && (
                        <button
                          className="completar-btn"
                          onClick={() => handleMarcarCompletada(reserva.id)}
                        >
                          Marcar como Completada
                        </button>
                      )}
                      <button
                        className="editar-btn"
                        onClick={() => handleEditarReserva(reserva)}
                      >
                        Editar
                      </button>
                      <button
                        className="eliminar-btn"
                        onClick={() => handleEliminarReserva(reserva.id)}
                      >
                        Eliminar
                      </button>
                    </div>
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      </div>

      {/* Formulario de Reserva */}
      <div className="formulario">
        <h4>{reservaEnEdicion ? 'Editar Reserva' : 'Crear Reserva'}</h4>
        <label>Selecciona un servicio:</label>
        <select
          onChange={handleServicioChange}
          value={selectedServicio?.id || ''}
          onClick={handleServicioDropdownClick}
        >
          <option value="">-- Seleccionar Servicio --</option>
          {loadingServicios && <option>Cargando servicios...</option>}
          {!loadingServicios &&
            servicios.map((servicio) => (
              <option key={servicio.id} value={servicio.id}>
                {servicio.nombre_servicio} - {servicio.duracion} min - $
                {parseFloat(servicio.costo).toLocaleString()}
              </option>
            ))}
          {!loadingServicios && servicios.length === 0 && (
            <option>No hay servicios disponibles</option>
          )}
        </select>

        <label>Hora de inicio (HH:MM):</label>
        <input type="time" value={startTime} onChange={handleStartTimeChange} />

        <label>Nombre del cliente:</label>
        <input
          type="text"
          value={clienteNombre}
          onChange={handleClienteNombreChange}
          placeholder="Nombre del cliente"
        />

        <label>Contacto del cliente:</label>
        <input
          type="text"
          value={clienteContacto}
          onChange={handleClienteContactoChange}
          placeholder="Número de contacto (opcional)"
        />

        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        <button
          className="reservar-btn"
          onClick={reservaEnEdicion ? handleActualizarReserva : handleReservar}
        >
          {reservaEnEdicion ? 'Actualizar' : 'Reservar'}
        </button>
        {reservaEnEdicion && (
          <button className="cancelar-btn" onClick={handleCancelarEdicion}>
            Cancelar
          </button>
        )}
      </div>
    </div>
  );
};

export default CalendarioReservas;
