// src/components/ModalProducto/ModalProducto.jsx
import React, { useState, useEffect } from 'react';
import './ModalProducto.css';

const ModalProducto = ({ producto, cerrarModal }) => {
  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api/';
  const [imagenModalAbierto, setImagenModalAbierto] = useState(false);
  const [imagenError, setImagenError] = useState(false);
  const [mostrarDescripcionLarga, setMostrarDescripcionLarga] = useState(false);
  const [textoLargo, setTextoLargo] = useState(false);

  const abrirImagenModal = () => {
    setImagenModalAbierto(true);
  };

  const cerrarImagenModal = () => {
    setImagenModalAbierto(false);
  };

  const handleImageError = () => {
    setImagenError(true);
  };

  const toggleDescripcionLarga = () => {
    setMostrarDescripcionLarga(!mostrarDescripcionLarga);
  };

  useEffect(() => {
    if (producto.descripcion_larga && producto.descripcion_larga.length > 200) {
      setTextoLargo(true);
    }
  }, [producto.descripcion_larga]);

  // Función para formatear el texto con saltos de línea
  const formatearTexto = (texto) => {
    return texto.split('\n').map((linea, index) => (
      <React.Fragment key={index}>
        {linea}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div className="productos-clientes-modal-overlay" onClick={cerrarModal}>
      <div className="productos-clientes-modal-content" onClick={(e) => e.stopPropagation()}>
        {/* Botón de Cierre del Modal Principal - Solo visible cuando el modal de imagen no está abierto */}
        {!imagenModalAbierto && (
          <button
            className="productos-clientes-modal-close"
            onClick={cerrarModal}
            aria-label="Cerrar Modal"
          >
            &times;
          </button>
        )}
        <div className="productos-clientes-modal-body">
          {/* Columna Izquierda */}
          <div className="productos-clientes-modal-left">
            <h2 className="productos-clientes-modal-nombre">{producto.nombre}</h2>

            <div className="productos-clientes-modal-image">
              {producto.imagen && !imagenError ? (
                <img
                  src={`${API_URL}productos/imagenes/${producto.imagen}`}
                  alt={producto.nombre}
                  onClick={abrirImagenModal}
                  onError={handleImageError}
                  className="imagen-producto"
                />
              ) : (
                <p>Imagen no disponible</p>
              )}
            </div>

            <p className="productos-clientes-modal-precio">
              <strong>Precio:</strong> ${producto.precio}
              <small> Los precios pueden variar, verifique en la tienda.</small>
            </p>
          </div>

          {/* Columna Derecha */}
          <div className="productos-clientes-modal-right">
            <div className="productos-clientes-modal-descripcion">
              <p><strong>Descripción:</strong></p>
              <p>{formatearTexto(producto.descripcion)}</p>

              {producto.descripcion_larga && (
                <div className="descripcion-larga-container">
                  <p><strong>Modo de uso:</strong></p>
                  <p
                    className={`descripcion-larga ${
                      mostrarDescripcionLarga ? 'expandida' : ''
                    }`}
                  >
                    {formatearTexto(producto.descripcion_larga)}
                  </p>

                  {textoLargo && (
                    <div className="productos-clientes-modal-toggle-container">
                      <button
                        className="productos-clientes-modal-toggle"
                        onClick={toggleDescripcionLarga}
                        aria-expanded={mostrarDescripcionLarga}
                        aria-controls="descripcion-larga"
                      >
                        {mostrarDescripcionLarga ? 'Ocultar' : 'Leer más'}
                      </button>
                    </div>
                  )}
                </div>
              )}

              {/* Disclaimer Añadido */}
              <div className="productos-clientes-modal-disclaimer">
                <p>Consulte con un profesional antes de usar este producto para asegurar su adecuación a su tipo de cabello.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal de Imagen Expandida */}
      {imagenModalAbierto && (
        <div
          className="productos-clientes-imagen-modal-overlay"
          onClick={cerrarImagenModal}
        >
          <div
            className="productos-clientes-imagen-modal-content"
            onClick={(e) => e.stopPropagation()} // Evita cerrar el modal principal al interactuar con el modal de imagen
          >
            {/* Botón de Cierre del Modal de Imagen */}
            <button
              className="productos-clientes-modal-close"
              onClick={cerrarImagenModal}
              aria-label="Cerrar Imagen Modal"
            >
              &times;
            </button>
            {producto.imagen && !imagenError ? (
              <img
                src={`${API_URL}productos/imagenes/${producto.imagen}`}
                alt={producto.nombre}
                onError={handleImageError}
                className="imagen-producto zoom-limited" // Mantener la clase para el tamaño de la imagen
              />
            ) : (
              <p>Imagen no disponible</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalProducto;
