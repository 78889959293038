// src/components/AgregarMarca/AgregarMarca.jsx
import React, { useState, useMemo, useEffect } from 'react';
import { toast } from 'react-toastify'; // Importar solo 'toast'
import debounce from 'lodash.debounce';
import './AgregarMarca.css'; // Asegúrate de tener este archivo CSS

const AgregarMarca = ({ API_URL, token, fetchMarcas }) => {
  const [nuevaMarca, setNuevaMarca] = useState({ nombre: '' });
  const [exists, setExists] = useState(false);
  const [checking, setChecking] = useState(false);

  // Memorización de la función debounced usando useMemo
  const verificarExistenciaMarca = useMemo(
    () =>
      debounce(async (nombre) => {
        if (!nombre.trim()) {
          setExists(false);
          return;
        }

        setChecking(true);
        try {
          const response = await fetch(`${API_URL}marcas`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (response.ok) {
            const data = await response.json();

            // Comparar de forma exacta ignorando mayúsculas y minúsculas
            const nombreLower = nombre.trim().toLowerCase();
            const existeMarca = data.some(
              (marca) => marca.nombre.trim().toLowerCase() === nombreLower
            );

            if (existeMarca) {
              setExists(true);
              toast.warn(`La marca "${nombre}" ya existe.`);
            } else {
              setExists(false);
            }
          } else {
            console.error('Error al verificar la existencia de la marca.');
          }
        } catch (error) {
          console.error('Error al verificar la existencia de la marca:', error);
        } finally {
          setChecking(false);
        }
      }, 500),
    [API_URL, token]
  );

  useEffect(() => {
    verificarExistenciaMarca(nuevaMarca.nombre);
    // Limpiar el debounce al desmontar el componente
    return () => verificarExistenciaMarca.cancel();
  }, [nuevaMarca.nombre, verificarExistenciaMarca]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNuevaMarca({ ...nuevaMarca, [name]: value });
  };

  const handleAgregarMarca = async () => {
    const { nombre } = nuevaMarca;

    // Validación básica
    if (!nombre.trim()) {
      toast.error('El nombre de la marca es obligatorio.');
      return;
    }

    if (exists) {
      toast.error(`La marca "${nombre}" ya existe.`);
      return;
    }

    try {
      const response = await fetch(`${API_URL}marcas`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(nuevaMarca),
      });

      const data = await response.json();

      if (response.ok) {
        setNuevaMarca({ nombre: '' });
        fetchMarcas(); // Actualizar la lista de marcas
        toast.success('Marca agregada exitosamente.');
      } else {
        if (data.errors) {
          const mensajes = data.errors.map((err) => err.msg).join('. ');
          toast.error(mensajes);
        } else {
          toast.error(data.error || 'Error al agregar la marca.');
        }
      }
    } catch (error) {
      toast.error('Error al agregar la marca.');
      console.error('Error al agregar la marca:', error);
    }
  };

  return (
    <div className="agregar-marca-formulario">
      <h3>Agregar Nueva Marca</h3>
      <input
        type="text"
        placeholder="Nombre de la marca"
        name="nombre"
        value={nuevaMarca.nombre}
        onChange={handleInputChange}
      />
      <button onClick={handleAgregarMarca} disabled={exists || checking}>
        {checking ? 'Verificando...' : 'Agregar Marca'}
      </button>
    </div>
  );
};

export default AgregarMarca;
