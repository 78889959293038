// src/Servicios/serviciosService.js

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api/';

export const fetchServicios = async (token) => {
  try {
    const response = await fetch(`${API_URL}servicios`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Error al obtener servicios: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error al obtener servicios:', error);
    throw error;
  }
};

export const createServicio = async (token, servicioData) => {
  try {
    const response = await fetch(`${API_URL}servicios`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(servicioData),
    });

    if (!response.ok) {
      throw new Error(`Error al crear servicio: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error al crear servicio:', error);
    throw error;
  }
};

export const updateServicio = async (token, id, servicioData) => {
  try {
    const response = await fetch(`${API_URL}servicios/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(servicioData),
    });

    if (!response.ok) {
      throw new Error(`Error al actualizar servicio: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error al actualizar servicio:', error);
    throw error;
  }
};

export const deleteServicio = async (token, id) => {
  try {
    const response = await fetch(`${API_URL}servicios/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Error al eliminar servicio: ${response.statusText}`);
    }

    if (response.status === 204) {
      // No hay contenido en la respuesta
      return null;
    } else {
      // Si hay contenido, lo analizamos como JSON
      return await response.json();
    }
  } catch (error) {
    console.error('Error al eliminar servicio:', error);
    throw error;
  }
};

export const fetchPuestos = async (token) => {
  const response = await fetch(`${API_URL}puestos`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error('Error al obtener los puestos');
  }
  const data = await response.json();
  return data;
};
