// src/components/Servicios/ServicioForm.jsx

import React from 'react';
import PropTypes from 'prop-types';
import './ServicioForm.css';

const ServicioForm = ({
  nombreServicio,
  setNombreServicio,
  costo,
  setCosto,
  duracion,
  setDuracion,
  descripcion,
  setDescripcion,
  gastos,
  setGastos,
  manoDeObra,
  setManoDeObra,
  handleSubmitServicio,
  resetForm,
  calcularTotal,
  modoEdicion,
}) => {
  const [gastoNombre, setGastoNombre] = React.useState('');
  const [gastoCosto, setGastoCosto] = React.useState('');

  const agregarGasto = () => {
    if (gastoNombre && gastoCosto) {
      setGastos([...gastos, { nombre: gastoNombre, costo: parseFloat(gastoCosto) }]);
      setGastoNombre('');
      setGastoCosto('');
    }
  };

  const eliminarGasto = (index) => {
    const nuevosGastos = gastos.filter((_, i) => i !== index);
    setGastos(nuevosGastos);
  };

  return (
    <form className="servicio-form" onSubmit={handleSubmitServicio}>
      <h2>{modoEdicion ? 'Editar Servicio' : 'Crear Servicio'}</h2>

      <div className="form-group">
        <label htmlFor="nombre_servicio">Nombre del Servicio:</label>
        <input
          id="nombre_servicio"
          type="text"
          value={nombreServicio}
          onChange={(e) => setNombreServicio(e.target.value)}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="duracion">Duración (en minutos):</label>
        <input
          id="duracion"
          type="number"
          value={duracion}
          onChange={(e) => setDuracion(e.target.value)}
          required
          min="0"
        />
      </div>

      <div className="form-group">
        <label htmlFor="descripcion">Descripción:</label>
        <textarea
          id="descripcion"
          value={descripcion}
          onChange={(e) => setDescripcion(e.target.value)}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="mano_de_obra">Mano de Obra:</label>
        <input
          id="mano_de_obra"
          type="number"
          value={manoDeObra}
          onChange={(e) => setManoDeObra(e.target.value)}
          min="0"
        />
      </div>

      <h3>Materiales y Otros Gastos</h3>
      <div className="gastos-input">
        <label htmlFor="gasto_nombre" className="sr-only">Nombre del gasto:</label>
        <input
          id="gasto_nombre"
          type="text"
          value={gastoNombre}
          onChange={(e) => setGastoNombre(e.target.value)}
          placeholder="Nombre del gasto"
        />
        <label htmlFor="gasto_costo" className="sr-only">Costo del gasto:</label>
        <input
          id="gasto_costo"
          type="number"
          value={gastoCosto}
          onChange={(e) => setGastoCosto(e.target.value)}
          placeholder="Costo del gasto"
          min="0"
        />
        <button type="button" className="btn-agregar-gasto" onClick={agregarGasto}>
          Agregar Gasto
        </button>
      </div>

      <div className="gastos-lista">
        {gastos.map((gasto, index) => (
          <div key={index} className="gasto-item">
            <span>
              {gasto.nombre}: ${gasto.costo.toLocaleString('es-CL')}
            </span>
            <button
              type="button"
              className="btn-eliminar-gasto"
              onClick={() => eliminarGasto(index)}
            >
              Eliminar
            </button>
          </div>
        ))}
      </div>

      <div className="form-total">
        <strong>Total:</strong> ${calcularTotal().toLocaleString('es-CL')}
      </div>

      <div className="form-buttons">
        <button type="submit" className="btn-submit">
          {modoEdicion ? 'Actualizar Servicio' : 'Crear Servicio'}
        </button>
        <button type="button" className="btn-cancel" onClick={resetForm}>
          Cancelar
        </button>
      </div>
    </form>
  );
};

ServicioForm.propTypes = {
  nombreServicio: PropTypes.string.isRequired,
  setNombreServicio: PropTypes.func.isRequired,
  costo: PropTypes.number.isRequired,
  setCosto: PropTypes.func.isRequired,
  duracion: PropTypes.string.isRequired,
  setDuracion: PropTypes.func.isRequired,
  descripcion: PropTypes.string.isRequired,
  setDescripcion: PropTypes.func.isRequired,
  gastos: PropTypes.array.isRequired,
  setGastos: PropTypes.func.isRequired,
  manoDeObra: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setManoDeObra: PropTypes.func.isRequired,
  handleSubmitServicio: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  calcularTotal: PropTypes.func.isRequired,
  modoEdicion: PropTypes.bool.isRequired,
};

export default ServicioForm;
