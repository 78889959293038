import React, { useState, useEffect } from 'react';

// Simulación de datos obtenidos de una base de datos
const obtenerDatosInventario = () => {
  return Promise.resolve({
    bodega: [
      { producto: 'Shampoo', cantidad: 15 },
      { producto: 'Acondicionador', cantidad: 5 },
      { producto: 'Gel', cantidad: 10 },
      { producto: 'Cera', cantidad: 3 }
    ],
    vitrina: [
      { producto: 'Shampoo', cantidad: 3 },
      { producto: 'Acondicionador', cantidad: 2 },
      { producto: 'Gel', cantidad: 1 },
      { producto: 'Cera', cantidad: 0 }
    ]
  });
};

const obtenerStockMinimo = () => {
  return Promise.resolve([
    { producto: 'Shampoo', stock_minimo: 5 },
    { producto: 'Acondicionador', stock_minimo: 5 },
    { producto: 'Gel', stock_minimo: 3 },
    { producto: 'Cera', stock_minimo: 2 }
  ]);
};

const obtenerTransferencias = () => {
  return Promise.resolve([
    { id: 1, producto: 'Shampoo', cantidad: 2, origen: 'Bodega', destino: 'Vitrina' },
    { id: 2, producto: 'Acondicionador', cantidad: 1, origen: 'Bodega', destino: 'Vitrina' }
  ]);
};

const Transferencias = () => {
  const [inventario, setInventario] = useState({ bodega: {}, vitrina: {}, stockMinimoVitrina: {} });
  const [transferencias, setTransferencias] = useState([]);
  const [bajoStock, setBajoStock] = useState([]);
  const [productoSeleccionado, setProductoSeleccionado] = useState('Shampoo');

  const [nuevaTransferencia, setNuevaTransferencia] = useState({
    producto: '',
    cantidad: 0,
  });

  useEffect(() => {
    const cargarDatos = async () => {
      const inventarioData = await obtenerDatosInventario();
      const stockMinimoData = await obtenerStockMinimo();
      const transferenciasData = await obtenerTransferencias();

      setInventario({
        bodega: inventarioData.bodega.reduce((acc, item) => {
          acc[item.producto] = item.cantidad;
          return acc;
        }, {}),
        vitrina: inventarioData.vitrina.reduce((acc, item) => {
          acc[item.producto] = item.cantidad;
          return acc;
        }, {}),
        stockMinimoVitrina: stockMinimoData.reduce((acc, item) => {
          acc[item.producto] = item.stock_minimo;
          return acc;
        }, {})
      });

      setTransferencias(transferenciasData);
    };

    cargarDatos();
  }, []);

  useEffect(() => {
    generarListaBajoStock();
  }, [inventario]);

  const generarListaBajoStock = () => {
    const productosBajoStock = Object.keys(inventario.vitrina).filter((producto) => {
      const stockVitrina = inventario.vitrina[producto] || 0;
      const stockBodega = inventario.bodega[producto] || 0;
      const stockMinimo = inventario.stockMinimoVitrina[producto] || 0;
      return stockVitrina < stockMinimo || stockBodega < 5;
    }).map((producto) => ({
      producto,
      stockVitrina: inventario.vitrina[producto],
      stockBodega: inventario.bodega[producto],
      stockMinimo: inventario.stockMinimoVitrina[producto],
    }));

    setBajoStock(productosBajoStock);
  };

  const registrarTransferencia = (producto, cantidad) => {
    const stockBodega = inventario.bodega[producto] || 0;
    const stockVitrina = inventario.vitrina[producto] || 0;

    if (stockBodega >= cantidad) {
      const nuevoInventario = {
        ...inventario,
        bodega: {
          ...inventario.bodega,
          [producto]: stockBodega - cantidad,
        },
        vitrina: {
          ...inventario.vitrina,
          [producto]: stockVitrina + cantidad,
        },
      };

      setInventario(nuevoInventario);
      setTransferencias([
        ...transferencias,
        { producto, cantidad, origen: 'Bodega', destino: 'Vitrina', id: transferencias.length + 1 },
      ]);
    } else {
      alert(`No hay suficiente stock de ${producto} en la bodega para transferir.`);
    }
  };

  const handleInputChange = (e) => {
    setNuevaTransferencia({ ...nuevaTransferencia, [e.target.name]: e.target.value });
  };

  const handleTransferencia = (e) => {
    e.preventDefault();
    const { producto, cantidad } = nuevaTransferencia;
    if (producto && cantidad > 0) {
      registrarTransferencia(producto, parseInt(cantidad, 10));
      setNuevaTransferencia({ producto: '', cantidad: 0 });
    } else {
      alert('Por favor, ingrese un producto y una cantidad válida.');
    }
  };

  const handleProductoSeleccionado = (e) => {
    setProductoSeleccionado(e.target.value);
  };

  const handleStockMinimoChange = (e) => {
    setInventario((prevInventario) => ({
      ...prevInventario,
      stockMinimoVitrina: {
        ...prevInventario.stockMinimoVitrina,
        [productoSeleccionado]: parseInt(e.target.value, 10),
      },
    }));
  };

  return (
    <div className="transferencias">
      <h2 className="mb-4">Transferencias de Productos</h2>

      <h3>Configurar Stock Mínimo en Vitrina</h3>
      <div className="mb-3">
        <label>Selecciona un producto:</label>
        <select
          className="form-control"
          value={productoSeleccionado}
          onChange={handleProductoSeleccionado}
        >
          {Object.keys(inventario.vitrina).map((producto) => (
            <option key={producto} value={producto}>
              {producto}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-3">
        <label>Stock mínimo para {productoSeleccionado}:</label>
        <input
          type="number"
          className="form-control"
          value={inventario.stockMinimoVitrina[productoSeleccionado]}
          onChange={handleStockMinimoChange}
          min="1"
        />
      </div>

      <form onSubmit={handleTransferencia} className="mb-4">
        <div className="row">
          <div className="col-md-5">
            <input
              type="text"
              name="producto"
              className="form-control"
              placeholder="Producto"
              value={nuevaTransferencia.producto}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="col-md-4">
            <input
              type="number"
              name="cantidad"
              className="form-control"
              placeholder="Cantidad"
              value={nuevaTransferencia.cantidad}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="col-md-3">
            <button type="submit" className="btn btn-success">Registrar Transferencia</button>
          </div>
        </div>
      </form>

      <h3>Historial de Transferencias</h3>
      {transferencias.length > 0 ? (
        <table className="table table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>Producto</th>
              <th>Cantidad</th>
              <th>Origen</th>
              <th>Destino</th>
            </tr>
          </thead>
          <tbody>
            {transferencias.map((transferencia) => (
              <tr key={transferencia.id}>
                <td>{transferencia.id}</td>
                <td>{transferencia.producto}</td>
                <td>{transferencia.cantidad}</td>
                <td>{transferencia.origen}</td>
                <td>{transferencia.destino}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No hay transferencias registradas.</p>
      )}
    </div>
  );
};

export default Transferencias;
