// src/components/ventas/ListaProductos.jsx

import React from 'react';
import DropdownWithCheckbox from './DropdownWithCheckbox';
import { toast } from 'react-toastify';

const ListaProductos = ({ productos, categorias, marcas, API_URL, token, fetchProductos }) => {
  const [expandedRows, setExpandedRows] = React.useState([]);
  const [productoEditando, setProductoEditando] = React.useState(null);

  const toggleRowExpansion = (id) => {
    setExpandedRows(prevState =>
      prevState.includes(id)
        ? prevState.filter(rowId => rowId !== id)
        : [...prevState, id]
    );
  };

  const handleEditarProducto = (producto) => {
    setProductoEditando({
      ...producto,
      categoria_ids: producto.categoria_ids || [],
      imagen: null,
    });
  };

  const handleEliminarProducto = async (id) => {
    if (!window.confirm('¿Estás seguro de que deseas eliminar este producto?')) return;

    try {
      const response = await fetch(`${API_URL}productos/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();

      if (response.ok) {
        fetchProductos();
        toast.success('Producto eliminado exitosamente.');
      } else {
        toast.error(data.error || 'Error al eliminar el producto.');
      }
    } catch (error) {
      toast.error('Error al eliminar el producto.');
      console.error('Error al eliminar el producto:', error);
    }
  };

  const handleGuardarEdicion = async () => {
    const { nombre } = productoEditando;

    if (!nombre.trim()) {
      toast.error('El nombre del producto es obligatorio.');
      return;
    }

    // Verificar si el nombre ha cambiado y si ya existe otro producto con ese nombre
    const productoOriginal = productos.find(p => p.id === productoEditando.id);
    if (productoOriginal && nombre.toLowerCase() !== productoOriginal.nombre.toLowerCase()) {
      try {
        const response = await fetch(`${API_URL}productos?nombre=${encodeURIComponent(nombre)}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          if (data.length > 0) {
            toast.error(`El producto "${nombre}" ya existe.`);
            return;
          }
        }
      } catch (error) {
        console.error('Error al verificar la existencia del producto:', error);
      }
    }

    const formData = new FormData();
    Object.keys(productoEditando).forEach((key) => {
      if (
        key !== 'id' &&
        productoEditando[key] !== '' &&
        productoEditando[key] !== null &&
        productoEditando[key] !== undefined &&
        (key !== 'imagen' || (key === 'imagen' && productoEditando.imagen instanceof File))
      ) {
        if (key === 'categoria_ids') {
          // Asegurar que los IDs son números antes de enviarlos
          productoEditando.categoria_ids.forEach(id => formData.append('categoria_ids', Number(id)));
        } else {
          formData.append(key, productoEditando[key]);
        }
      }
    });

    try {
      const response = await fetch(`${API_URL}productos/${productoEditando.id}`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          // No se debe establecer 'Content-Type' cuando se usa FormData
        },
        body: formData,
      });

      const data = await response.json();

      if (response.ok) {
        setProductoEditando(null);
        fetchProductos();
        toast.success('Producto actualizado exitosamente.');
      } else {
        if (data.errors) {
          const mensajes = data.errors.map((err) => err.msg).join('. ');
          toast.error(mensajes);
        } else {
          toast.error(data.error || 'Error al actualizar el producto.');
        }
      }
    } catch (error) {
      toast.error('Error al actualizar el producto.');
      console.error('Error al actualizar el producto:', error);
    }
  };

  const handleInputChange = (e, isEditing = false) => {
    const { name, value } = e.target;
    if (isEditing) {
      setProductoEditando({ ...productoEditando, [name]: value });
    }
  };

  const handleImageChange = (e, isEditing = false) => {
    const file = e.target.files[0];
    if (isEditing) {
      setProductoEditando({ ...productoEditando, imagen: file });
    }
  };

  return (
    <div>
      <h3>Lista de Productos</h3>
      <table className="productos-admin-tabla-productos">
        <thead>
          <tr>
            <th>Imagen</th>
            <th>Nombre</th>
            <th>Precio</th>
            <th>Stock</th>
            <th>Descripción</th>
            <th>Instrucciones</th>
            <th>Categorías</th>
            <th>Marca</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {productos.map((producto) => {
            const isExpanded = expandedRows.includes(producto.id);
            const categoriasProducto = categorias.filter(cat => producto.categoria_ids.includes(cat.id));
            return (
              <tr key={producto.id}>
                {/* Imagen */}
                <td>
                  {productoEditando?.id === producto.id ? (
                    <>
                      {producto.imagen && (
                        <img
                          src={`${API_URL}productos/imagenes/${producto.imagen}`}
                          alt={producto.nombre}
                          className="imagen-tabla"
                          loading="lazy"
                        />
                      )}
                      <input type="file" onChange={(e) => handleImageChange(e, true)} />
                    </>
                  ) : (
                    producto.imagen && (
                      <img
                        src={`${API_URL}productos/imagenes/${producto.imagen}`}
                        alt={producto.nombre}
                        className="imagen-tabla"
                      />
                    )
                  )}
                </td>
                
                {/* Nombre */}
                <td>
                  {productoEditando?.id === producto.id ? (
                    <input
                      type="text"
                      name="nombre"
                      value={productoEditando.nombre}
                      onChange={(e) => handleInputChange(e, true)}
                    />
                  ) : (
                    producto.nombre
                  )}
                </td>
                
                {/* Precio */}
                <td>
                  {productoEditando?.id === producto.id ? (
                    <input
                      type="number"
                      name="precio"
                      value={productoEditando.precio}
                      onChange={(e) => handleInputChange(e, true)}
                    />
                  ) : (
                    `$${Number(producto.precio).toFixed(2)}`
                  )}
                </td>
                
                {/* Stock */}
                <td>
                  {productoEditando?.id === producto.id ? (
                    <input
                      type="number"
                      name="stock"
                      value={productoEditando.stock}
                      onChange={(e) => handleInputChange(e, true)}
                    />
                  ) : (
                    producto.stock
                  )}
                </td>
                
                {/* Descripción */}
                <td>
                  {productoEditando?.id === producto.id ? (
                    <textarea
                      name="descripcion"
                      value={productoEditando.descripcion}
                      onChange={(e) => handleInputChange(e, true)}
                      placeholder="Descripción corta"
                    ></textarea>
                  ) : (
                    <div
                      className={`descripcion-cell ${isExpanded ? 'expanded' : 'truncated'}`}
                      onClick={() => toggleRowExpansion(producto.id)}
                    >
                      {producto.descripcion}
                    </div>
                  )}
                </td>
                
                {/* Instrucciones */}
                <td>
                  {productoEditando?.id === producto.id ? (
                    <textarea
                      name="descripcion_larga"
                      value={productoEditando.descripcion_larga}
                      onChange={(e) => handleInputChange(e, true)}
                      placeholder="Descripción detallada"
                    ></textarea>
                  ) : (
                    <div
                      className={`descripcion-larga-cell ${isExpanded ? 'expanded' : 'truncated'}`}
                      onClick={() => toggleRowExpansion(producto.id)}
                    >
                      {producto.descripcion_larga}
                    </div>
                  )}
                </td>
                
                {/* Categorías */}
                <td>
                  {productoEditando?.id === producto.id ? (
                    <DropdownWithCheckbox
                      label=""
                      options={categorias.map(cat => ({
                        id: cat.id,
                        nombre: cat.nombre,
                      }))}
                      selectedOptions={productoEditando.categoria_ids.map(id => Number(id))}
                      setSelectedOptions={(selected) => {
                        const categoriasSeleccionadas = selected.map(id => Number(id));
                        setProductoEditando({ ...productoEditando, categoria_ids: categoriasSeleccionadas });
                      }}
                      isMultiSelect={true}
                      readOnly={false}
                    />
                  ) : (
                    <DropdownWithCheckbox
                      label=""
                      options={categoriasProducto.map(cat => ({
                        id: cat.id,
                        nombre: cat.nombre,
                      }))}
                      selectedOptions={[]} // No mostrar categorías seleccionadas en el header
                      setSelectedOptions={() => {}} // Función vacía ya que es solo lectura
                      isMultiSelect={true}
                      readOnly={false} // Permitir ver las categorías al desplegar
                    />
                  )}
                </td>
                
                {/* Marca */}
                <td>
                  {productoEditando?.id === producto.id ? (
                    <DropdownWithCheckbox
                      label=""
                      options={marcas.map(marca => ({
                        id: marca.id,
                        nombre: marca.nombre,
                      }))}
                      selectedOptions={productoEditando.marca_id ? [Number(productoEditando.marca_id)] : []}
                      setSelectedOptions={(selected) => {
                        const marcaSeleccionada = selected.length > 0 ? Number(selected[0]) : '';
                        setProductoEditando({ ...productoEditando, marca_id: marcaSeleccionada });
                      }}
                      isMultiSelect={false}
                      readOnly={false}
                    />
                  ) : (
                    marcas.find(marca => marca.id === producto.marca_id)?.nombre || 'Sin marca'
                  )}
                </td>
                
                {/* Acciones */}
                <td>
                  {productoEditando                    ?.id === producto.id ? (
                    <>
                      <button className="btn-guardar" onClick={handleGuardarEdicion}>Guardar</button>
                      <button className="btn-cancelar" onClick={() => setProductoEditando(null)}>Cancelar</button>
                    </>
                  ) : (
                    <>
                      <button className="btn-editar" onClick={() => handleEditarProducto(producto)}>Editar</button>
                      <button className="btn-eliminar" onClick={() => handleEliminarProducto(producto.id)}>Eliminar</button>
                    </>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ListaProductos;