// src/Servicios/puestosService.js

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api/';

export const fetchPuestos = async (token) => {
  const response = await fetch(`${API_URL}puestos`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error('Error al obtener los puestos');
  }
  const data = await response.json();
  return data;
};

export const deletePuesto = async (token, puestoId) => {
  const response = await fetch(`${API_URL}puestos/${puestoId}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error('Error al eliminar el puesto');
  }
};

export const updatePuesto = async (token, puestoId, puestoData) => {
  const response = await fetch(`${API_URL}puestos/${puestoId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(puestoData),
  });
  if (!response.ok) {
    throw new Error('Error al actualizar el puesto');
  }
  return await response.json();
};

export const createPuesto = async (token, puestoData) => {
  const response = await fetch(`${API_URL}puestos`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(puestoData),
  });
  if (!response.ok) {
    throw new Error('Error al crear el puesto');
  }
  return await response.json();
};

export const fetchEstadisticas = async (token, puestoId) => {
  const response = await fetch(`${API_URL}puestos/${puestoId}/estadisticas`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error('Error al obtener las estadísticas');
  }
  const data = await response.json();
  return data;
};
