// src/components/DashboardContent/DashboardContent.js

import React, { useState, useEffect } from 'react';
import CalendarioReservas from '../Calendario/CalendarioReservas';
import AgregarPuesto from '../AgregarPuesto/AgregarPuesto';
import {
  fetchPuestos,
  deletePuesto,
  updatePuesto,
  fetchEstadisticas,
} from '../Servicios/puestosService';

const DashboardContent = () => {
  const [puestos, setPuestos] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const loadPuestos = async () => {
      const token = localStorage.getItem('token');
      try {
        const data = await fetchPuestos(token);
        const puestosConEstadisticas = await Promise.all(
          data.map(async (puesto) => {
            const estadisticas = await fetchEstadisticas(token, puesto.id);
            return { ...puesto, estadisticas };
          })
        );
        setPuestos(puestosConEstadisticas);
      } catch (error) {
        setError('Error al obtener los puestos.');
        console.error('Error al obtener los puestos:', error);
      }
    };

    loadPuestos();
  }, []);

  const handlePuestoAdded = (nuevoPuesto) => {
    setPuestos((prevPuestos) => [...prevPuestos, nuevoPuesto]);
  };

  const handleEliminarPuesto = async (id) => {
    const token = localStorage.getItem('token');
    try {
      await deletePuesto(token, id);
      setPuestos(puestos.filter((puesto) => puesto.id !== id));
      alert('Puesto eliminado exitosamente');
    } catch (error) {
      console.error('Error al eliminar el puesto:', error);
      alert('No se pudo eliminar el puesto.');
    }
  };

  const handleEditarPuesto = async (id, updatedData) => {
    const token = localStorage.getItem('token');
    try {
      await updatePuesto(token, id, updatedData);
      setPuestos(
        puestos.map((puesto) =>
          puesto.id === id ? { ...puesto, ...updatedData } : puesto
        )
      );
      alert('Puesto actualizado exitosamente');
    } catch (error) {
      console.error('Error al actualizar el puesto:', error);
      alert('No se pudo actualizar el puesto.');
    }
  };

  return (
    <div className="dashboard-content-inner">
      {error && <p className="error-text">{error}</p>}

      <div className="gestion-puestos">
        <h2>Gestión de Puestos</h2>
        {puestos.length > 0 ? (
          puestos.map((puesto) => (
            <div key={puesto.id} className="puesto-card">
              <div className="puesto-info">
                <div className="puesto-details">
                  <h3>{puesto.nombre}</h3>
                  <p>
                    <strong>Persona a cargo:</strong> {puesto.nombrePersona}
                  </p>
                  <p>
                    <strong>Ubicación:</strong> {puesto.ubicacion}
                  </p>
                  <p>
                    <strong>Descripción:</strong> {puesto.descripcion}
                  </p>
                </div>
                <div className="puesto-estadisticas">
                  <h4>Estadísticas:</h4>
                  {puesto.estadisticas && puesto.estadisticas.length > 0 ? (
                    puesto.estadisticas.map((stat) => (
                      <div key={stat.estado}>
                        <strong>{stat.estado}:</strong> {stat.cantidad} reservas, Total
                        ganancias: $
                        {parseFloat(stat.total_ganancias).toLocaleString()}
                      </div>
                    ))
                  ) : (
                    <p>No hay estadísticas disponibles.</p>
                  )}
                </div>
              </div>

              <CalendarioReservas
                puesto={puesto}
                token={localStorage.getItem('token')}
              />

              <div className="puesto-buttons">
                <button
                  className="btn btn-editar"
                  onClick={() =>
                    handleEditarPuesto(puesto.id, { nombre: 'Nuevo Nombre' })
                  }
                >
                  Editar
                </button>
                <button
                  className="btn btn-eliminar"
                  onClick={() => handleEliminarPuesto(puesto.id)}
                >
                  Eliminar
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>No hay puestos registrados.</p>
        )}
      </div>

      <div className="agregar-puesto-container">
        <h2>Agregar Nuevo Puesto</h2>
        <AgregarPuesto onPuestoAdded={handlePuestoAdded} />
      </div>
    </div>
  );
};

export default DashboardContent;
