import React, { useState } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import GoogleLoginButton from '../GoogleLoginButton/GoogleLoginButton';
import { useAuth } from '../../contexts/AuthContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';

const LoginModal = ({ setShowLoginModal, onLoginSuccess }) => {
  const [loginFormData, setLoginFormData] = useState({ correo: '', contraseña: '' });
  const [loginError, setLoginError] = useState(null);
  const [loginLoading, setLoginLoading] = useState(false);
  const { login } = useAuth();

  const backendUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001'; // URL desde .env

  const handleLoginInputChange = (e) => {
    const { name, value } = e.target;
    setLoginFormData((prevState) => ({
      ...prevState,
      [name]: value.trim(), // Eliminar espacios en blanco
    }));
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setLoginLoading(true);
    setLoginError(null);

    try {
      // Normalizar el correo antes de enviarlo
      const loginData = {
        ...loginFormData,
        correo: loginFormData.correo.trim().toLowerCase(),
      };

      const response = await fetch(`${backendUrl}auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginData), // Enviar los datos de inicio de sesión
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error('Correo o contraseña incorrectos.');
      }

      if (!data.user) {
        throw new Error('Error inesperado. Intente nuevamente más tarde.');
      }

      // Guardar el token en el contexto de autenticación
      login(data); // Almacena el token y datos del usuario

      // Llamar a onLoginSuccess si está definido
      if (onLoginSuccess) {
        onLoginSuccess();
      }

      // Cerrar el modal después de un inicio de sesión exitoso
      setShowLoginModal(false);
    } catch (error) {
      setLoginError(error.message || 'Error al iniciar sesión. Intente nuevamente.');
    } finally {
      setLoginLoading(false);
    }
  };

  return (
    <Modal show={true} onHide={() => setShowLoginModal(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>Iniciar Sesión</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loginError && <Alert variant="danger">{loginError}</Alert>}
        <Form onSubmit={handleLoginSubmit} autoComplete="off">
          <Form.Group controlId="formCorreo">
            <Form.Label>Correo Electrónico</Form.Label>
            <Form.Control
              type="email"
              placeholder="Ingresa tu correo"
              name="correo"
              value={loginFormData.correo}
              onChange={handleLoginInputChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formContraseña">
            <Form.Label>Contraseña</Form.Label>
            <Form.Control
              type="password"
              placeholder="Ingresa tu contraseña"
              name="contraseña"
              value={loginFormData.contraseña}
              onChange={handleLoginInputChange}
              required
            />
          </Form.Group>

          {/* Enlace de Recuperar Clave */}
          <div className="text-right mt-2">
            <Link to="/forgot-password" onClick={() => setShowLoginModal(false)}>
              ¿Olvidaste tu contraseña?
            </Link>
          </div>

          <Button variant="primary" type="submit" className="w-100 mt-3" disabled={loginLoading}>
            {loginLoading ? 'Ingresando...' : 'Iniciar Sesión'}
          </Button>
        </Form>

        <div className="text-center mt-3">o</div>
        <div className="text-center mt-3">
          <GoogleLoginButton
            onLoginSuccess={() => {
              if (onLoginSuccess) {
                onLoginSuccess();
              }
              setShowLoginModal(false);
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowLoginModal(false)}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LoginModal;
