  import React, { useEffect, useState, useCallback } from 'react';
  import { Link, useNavigate } from 'react-router-dom';
  import Slider from 'react-slick';
  import { Helmet } from 'react-helmet-async';
  import './Home.css';
  import 'slick-carousel/slick/slick.css';
  import 'slick-carousel/slick/slick-theme.css';

  const Home = () => {
    const [showBanner, setShowBanner] = useState(true);
    const [productosAleatorios, setProductosAleatorios] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const navigate = useNavigate();
    const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api/';

    const fetchProductos = useCallback(async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_URL}productos/destacados`);
        if (!response.ok) throw new Error('Error al obtener los productos');
        const data = await response.json();
        setProductosAleatorios(data);
      } catch (error) {
        setError('No se pudieron cargar los productos.');
        console.error(error);
      } finally {
        setLoading(false);
      }
    }, [API_URL]);

    useEffect(() => {
      fetchProductos();
    }, [fetchProductos]);

    const NextArrow = (props) => {
      const { className, style, onClick } = props;
      return (
        <div className={`${className} custom-arrow next-arrow`} style={{ ...style }} onClick={onClick}>
          &#9654;
        </div>
      );
    };

    const PrevArrow = (props) => {
      const { className, style, onClick } = props;
      return (
        <div className={`${className} custom-arrow prev-arrow`} style={{ ...style }} onClick={onClick}>
          &#9664;
        </div>
      );
    };

    const settings = {
      dots: true,
      infinite: true,
      speed: 600,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      pauseOnHover: true,
      arrows: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        { breakpoint: 1024, settings: { slidesToShow: 2 } },
        { breakpoint: 768, settings: { slidesToShow: 1 } },
      ],
    };

    return (
      <>
        {/* SEO con React Helmet */}
        <Helmet>
          <title>Maryland Beauty & Care | Productos para el Cabello y Servicios de Peluquería</title>
          <meta
            name="description"
            content="Maryland Beauty & Care ofrece los mejores productos para el cuidado del cabello, además de servicios exclusivos de peluquería en Santa Cruz, 6ta Región."
          />
          {/* Marcado de datos estructurados (SEO local) */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "HairSalon",
                "name": "Maryland Beauty & Care",
                "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "Rafael Casanova 222",
                  "addressLocality": "Santa Cruz",
                  "addressRegion": "VI Región",
                  "postalCode": "3230000",
                  "addressCountry": "CL"
                },
                "url": "https://marylandbeauty.cl"
              }
            `}
          </script>
        </Helmet>

        <div className="home-container">
          {showBanner && (
            <div className="construction-banner">
              🚧 Este sitio está en construcción. Algunas funcionalidades pueden no estar disponibles. 🚧
              <button className="close-banner-btn" onClick={() => setShowBanner(false)} aria-label="Cerrar aviso de construcción">
                ✖
              </button>
            </div>
          )}

          {/* Carrusel de Productos Destacados */}
          <section className="featured-products py-5">
            <div className="container">
              <h2 className="text-center mb-5">Productos Destacados</h2>
              {loading ? (
                <div className="text-center">Cargando productos...</div>
              ) : error ? (
                <div className="text-center">Error: {error}</div>
              ) : (
                <div className="carousel-wrapper">
                  <Slider {...settings}>
                    {productosAleatorios.map((producto) => (
                      <div
                        className="product-card"
                        key={producto.id}
                        onClick={() => navigate(`/productos/${producto.id}`)}
                        style={{ cursor: 'pointer' }}
                      >
                        {producto.imagen && (
                          <img
                            src={`${API_URL}productos/imagenes/${producto.imagen}`}
                            alt={producto.nombre}
                            className="img-fluid mb-3 product-image"
                            loading="lazy"
                          />
                        )}
                        <h5>{producto.nombre}</h5>
                        <pre style={{ whiteSpace: 'pre-wrap', fontFamily: 'inherit' }}>{producto.descripcion}</pre>
                      </div>
                    ))}
                  </Slider>
                </div>
              )}
              <div className="text-center mt-4">
                <Link to="/productos" className="btn btn-secondary">
                  Ver Todos los Productos
                </Link>
              </div>
            </div>
          </section>

          {/* Servicios */}
          <section className="services py-5 bg-light">
            <div className="container">
              <h2 className="text-center mb-5">Nuestros Servicios de Peluquería</h2>
              <div className="row">
                <div className="col-md-6 mb-4">
                  <div className="service-card text-center">
                    <img src={`${process.env.PUBLIC_URL}/images/1.webp`} alt="Corte de Pelo" className="img-fluid mb-3" />
                    <h5>Corte de Pelo</h5>
                    <p>Con los mejores estilistas, te ofrecemos cortes modernos y clásicos adaptados a tu estilo.</p>
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <div className="service-card text-center">
                    <img src={`${process.env.PUBLIC_URL}/images/1.webp`} alt="Tratamientos Capilares" className="img-fluid mb-3" />
                    <h5>Tratamientos Capilares</h5>
                    <p>Los mejores tratamientos para mantener tu cabello saludable y radiante.</p>
                  </div>
                </div>
              </div>
              <div className="text-center mt-4">
                <Link to="/servicios" className="btn btn-secondary">
                  Ver Todos los Servicios
                </Link>
              </div>
            </div>
          </section>

          {/* Sobre Nosotros */}
          <section className="about-us py-5">
            <div className="container">
              <h2 className="text-center mb-4">Sobre Nosotros</h2>
              <p className="text-center">
                En Maryland Beauty & Care, nos especializamos en ofrecer los mejores productos y
                servicios para el cuidado personal. Nuestra misión es resaltar tu belleza natural con
                productos de alta calidad y atención personalizada.
              </p>
            </div>
          </section>
        </div>
      </>
    );
  };

  export default Home;
