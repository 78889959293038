// empleadosService.js

// Recupera la URL base de la API desde las variables de entorno
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api/';

// Obtener lista de empleados
export const fetchEmpleados = async () => {
  const token = localStorage.getItem('token');

  try {
    const response = await fetch(`${API_URL}empleados`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Error ${response.status}: ${errorText}`);
    }

    const empleados = await response.json();
    return empleados;
  } catch (error) {
    console.error('Error al obtener empleados:', error.message);
    throw error;
  }
};

// Crear un empleado nuevo
export const createEmpleado = async (token, empleadoData) => {
  try {
    const response = await fetch(`${API_URL}empleados`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(empleadoData),
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Error ${response.status}: ${errorText}`);
    }

    const newEmpleado = await response.json();
    return newEmpleado;
  } catch (error) {
    console.error('Error al crear empleado:', error.message);
    throw error;
  }
};

// Actualizar un empleado existente
export const updateEmpleado = async (id, empleadoData) => {
  const token = localStorage.getItem('token');

  try {
    const response = await fetch(`${API_URL}empleados/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(empleadoData),
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Error ${response.status}: ${errorText}`);
    }

    const updatedEmpleado = await response.json();
    return updatedEmpleado;
  } catch (error) {
    console.error('Error al actualizar empleado:', error.message);
    throw error;
  }
};

// Eliminar un empleado
export const deleteEmpleado = async (id) => {
  const token = localStorage.getItem('token');

  try {
    const response = await fetch(`${API_URL}empleados/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Error ${response.status}: ${errorText}`);
    }

    return { message: 'Empleado eliminado con éxito' };
  } catch (error) {
    console.error('Error al eliminar empleado:', error.message);
    throw error;
  }
};
