// src/components/Navbar/SearchBar.js
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import './SearchBar.css';

const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [placeholder, setPlaceholder] = useState('Buscar productos, categorías o marcas...');

  const navigate = useNavigate();

  const fetchSuggestions = useCallback(async (term) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}search/sugerencias?termino=${encodeURIComponent(term)}`
      );
      if (!response.ok) {
        const textError = await response.text();
        console.error('Respuesta no OK:', textError);
        throw new Error('Error al obtener sugerencias');
      }
      const data = await response.json();
      if (data.length === 0) {
        setNoResults(true);
      } else {
        setNoResults(false);
      }
      setSuggestions(data.slice(0, 5));
    } catch (error) {
      console.error('Error al obtener sugerencias:', error);
      setSuggestions([]);
      setNoResults(true);
    }
  }, []);

  const debouncedFetchSuggestions = useMemo(() => debounce(fetchSuggestions, 300), [fetchSuggestions]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value.length < 2) {
      setSuggestions([]);
      setNoResults(false);
      return;
    }

    debouncedFetchSuggestions(value);
  };

  const handleSuggestionClick = (id, tipo) => {
    switch (tipo) {
      case 'producto':
        navigate(`/productos/${id}`);
        break;
      case 'categoria':
        navigate(`/productos?categoria=${id}`);
        break;
      case 'marca':
        navigate(`/productos?marca=${id}`);
        break;
      default:
        navigate('/productos');
        break;
    }
    setSearchTerm('');
    setSuggestions([]);
    setNoResults(false);
  };

  const handleClearClick = () => {
    setSearchTerm('');
    setSuggestions([]);
    setNoResults(false);
  };

  useEffect(() => {
    const updatePlaceholder = () => {
      if (window.innerWidth <= 768) {
        setPlaceholder('Buscar...');
      } else {
        setPlaceholder('Buscar productos, categorías o marcas...');
      }
    };

    updatePlaceholder();
    window.addEventListener('resize', updatePlaceholder);

    return () => {
      window.removeEventListener('resize', updatePlaceholder);
    };
  }, []);

  return (
    <div className="search-bar-container">
      <div className="search-bar">
        <input
          type="text"
          placeholder={placeholder}
          value={searchTerm}
          onChange={handleInputChange}
          className="search-input"
          aria-label="Buscar productos, categorías o marcas"
        />
        {searchTerm && (
          <button 
            type="button" 
            className="clear-button" 
            onClick={handleClearClick}
            aria-label="Limpiar búsqueda"
          >
            ×
          </button>
        )}
      </div>
      {(suggestions.length > 0 || noResults) && (
        <ul className="suggestions-list">
          {noResults ? (
            <li className="suggestion-item no-results">No se encontraron resultados</li>
          ) : (
            suggestions.map((suggestion) => (
              <li
                key={`${suggestion.tipo}-${suggestion.id}`}
                onClick={() => handleSuggestionClick(suggestion.id, suggestion.tipo)}
                className="suggestion-item"
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSuggestionClick(suggestion.id, suggestion.tipo);
                  }
                }}
              >
                {suggestion.resultado} <span className="suggestion-type">({suggestion.tipo})</span>
              </li>
            ))
          )}
        </ul>
      )}
    </div>
  );
};

export default SearchBar;
