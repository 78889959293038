import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import './UserProfile.css';  // Importamos el archivo de estilos

const UserProfile = () => {
  const [userData, setUserData] = useState(null);
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api/';

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/');
    } else {
      const token = localStorage.getItem('token');
      fetch(`${API_URL}protected/perfil`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) throw new Error('Error al obtener datos del perfil');
          return response.json();
        })
        .then((data) => {
          setUserData(data);
        })
        .catch((error) => console.error('Error al obtener datos del perfil:', error));
    }
  }, [isAuthenticated, navigate, API_URL]);

  return (
    <div className="user-profile-container">
      <div className="user-profile-header">
        <h1>Perfil del Usuario</h1>
      </div>

      {userData ? (
        <div className="user-profile-details">
          <div>
            <p><span className="label">Nombre:</span> {userData.nombre} {userData.apellido}</p>
            <p><span className="label">Correo:</span> {userData.correo}</p>
            <p><span className="label">Edad:</span> {userData.edad}</p>
          </div>
          <div>
            <p><span className="label">Rol:</span> {userData.tipo_usuario}</p>
            <p><span className="label">Fecha de Registro:</span> {new Date(userData.fecha_registro).toLocaleDateString()}</p>
          </div>
        </div>
      ) : (
        <p>Cargando datos del perfil...</p>
      )}

      <div className="user-profile-footer">
        <button onClick={() => navigate('/editar-perfil')}>Editar Perfil</button>
      </div>
    </div>
  );
};

export default UserProfile;
