import React, { useState, useEffect } from 'react';
import '../UserProfile/UserProfile.css';
import {
  FaStar,
  FaRegStar,
  FaStarHalfAlt,
  FaEnvelope,
  FaPhone,
  FaMapMarkerAlt,
  FaGlobe,
  FaTwitter,
  FaFacebook,
  FaLinkedin,
  FaInfoCircle,
} from 'react-icons/fa';
import {
  initialUser,
  cargarHistorialPartidos,
  cargarCalificaciones,
  cargarReportes,
  cargarSanciones,
  cargarNotificaciones,
  cargarCitas,
  cargarEstadisticas,
} from '../fakeUserData';

const Dashboard = () => {
  const [user, setUser] = useState(initialUser);

  useEffect(() => {
    cargarDatosAdicionales();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cargarDatosAdicionales = () => {
    // Cargar Historial de Partidos
    const partidosFicticios = cargarHistorialPartidos();
    setUser((prevUser) => ({
      ...prevUser,
      partidos: partidosFicticios,
    }));

    // Cargar Calificaciones
    const calificacionesFicticias = cargarCalificaciones();
    setUser((prevUser) => ({
      ...prevUser,
      calificaciones: calificacionesFicticias,
    }));

    // Cargar Reportes
    const reportesFicticios = cargarReportes();
    setUser((prevUser) => ({
      ...prevUser,
      reportes: reportesFicticios,
    }));

    // Cargar Sanciones
    const sancionesFicticias = cargarSanciones();
    setUser((prevUser) => ({
      ...prevUser,
      sanciones: sancionesFicticias,
    }));

    // Cargar Notificaciones
    const notificacionesFicticias = cargarNotificaciones();
    setUser((prevUser) => ({
      ...prevUser,
      notificaciones: notificacionesFicticias,
    }));

    // Cargar Citas (Solo si el usuario es Arrendador)
    if (initialUser.tipo_usuario === 'Arrendador') {
      const citasFicticias = cargarCitas();
      setUser((prevUser) => ({
        ...prevUser,
        citas: citasFicticias,
      }));
    }

    // Cargar Estadísticas
    const estadisticasFicticias = cargarEstadisticas();
    setUser((prevUser) => ({
      ...prevUser,
      estadisticas: estadisticasFicticias,
    }));
  };

  // Función para calcular la reputación promedio
  const calcularReputacion = () => {
    if (user.calificaciones.length === 0) return 0;
    const total = user.calificaciones.reduce((acc, curr) => acc + curr.puntuacion, 0);
    return total / user.calificaciones.length;
  };

  // Función para renderizar estrellas de reputación
  const renderEstrellas = () => {
    const reputacion = calcularReputacion();
    const estrellas = [];
    for (let i = 1; i <= 5; i++) {
      if (reputacion >= i) {
        estrellas.push(<FaStar key={i} color="#FFD700" />);
      } else if (reputacion >= i - 0.5) {
        estrellas.push(<FaStarHalfAlt key={i} color="#FFD700" />);
      } else {
        estrellas.push(<FaRegStar key={i} color="#FFD700" />);
      }
    }
    return estrellas;
  };

  return (
    <div className="user-profile">
      {/* Header con Tarjetas de Perfil y Estadísticas */}
      <div className="header">
        <div className="card profile-card">
        <img src="/profile.jpg" alt="Foto de Perfil" className="profile-photo" />

          <div className="profile-info">
            <h2>
              {user.nombre} {user.apellido}
            </h2>
            <p>
              <FaEnvelope className="icon" /> {user.correo}
            </p>
            <p>
              <FaPhone className="icon" /> {user.telefono}
            </p>
            <p>
              <FaMapMarkerAlt className="icon" /> {user.direccion}
            </p>
            <p>
              <strong>Estado:</strong> {user.estado}
            </p>
            <p>
              <strong>Edad:</strong> {user.edad}
            </p>
            <p>
              <strong>Género:</strong> {user.genero}
            </p>
            <p>
              <strong>Tipo de Usuario:</strong> {user.tipo_usuario}
            </p>
            <p>
              <strong>Fecha de Nacimiento:</strong>{' '}
              {new Date(user.fecha_nacimiento).toLocaleDateString()}
            </p>
            <p>
              <strong>Idiomas:</strong> {user.idiomas.join(', ')}
            </p>
            <p>
              <strong>Habilidades:</strong> {user.habilidades.join(', ')}
            </p>
            <p>
              <strong>Preferencias:</strong> Posición: {user.rol.tipo}
              <span className="tooltip">
                <FaInfoCircle className="info-icon" />
                <span className="tooltip-text">{user.rol.descripcion}</span>
              </span>
            </p>
            <div className="reputacion">
              <strong>Reputación:</strong> {renderEstrellas()} ({calcularReputacion().toFixed(1)} / 5)
            </div>
            <div className="redes-sociales">
              {user.redes_sociales.twitter && (
                <a href={user.redes_sociales.twitter} target="_blank" rel="noopener noreferrer">
                  <FaTwitter />
                </a>
              )}
              {user.redes_sociales.facebook && (
                <a href={user.redes_sociales.facebook} target="_blank" rel="noopener noreferrer">
                  <FaFacebook />
                </a>
              )}
              {user.redes_sociales.linkedin && (
                <a href={user.redes_sociales.linkedin} target="_blank" rel="noopener noreferrer">
                  <FaLinkedin />
                </a>
              )}
              {user.redes_sociales.sitio_web && (
                <a href={user.redes_sociales.sitio_web} target="_blank" rel="noopener noreferrer">
                  <FaGlobe />
                </a>
              )}
            </div>
          </div>
        </div>

        <div className="card estadisticas">
          <h3>Estadísticas de Uso</h3>
          <ul>
            <li>
              <strong>Total de Canchas Utilizadas:</strong> {user.estadisticas.total_canchas}
            </li>
            <li>
              <strong>Total de Partidos Jugados:</strong> {user.estadisticas.total_partidos}
            </li>
            <li>
              <strong>Total de Inscripciones:</strong> {user.estadisticas.total_inscripciones}
            </li>
            <li>
              <strong>Promedio de Puntuación:</strong> {user.estadisticas.promedio_puntuacion}/5
            </li>
          </ul>
        </div>
      </div>

      {/* Contenedor Principal con Tarjetas para Cada Sección */}
      <div className="main-content">
        {/* Ubicación Preferida */}
        <div className="card seccion">
          <h3>Ubicación Preferida</h3>
          <p>
            <strong>Región:</strong> Norte
          </p>
        </div>

        {/* Historial de Partidos */}
        <div className="card seccion">
          <h3>Historial de Partidos</h3>
          {user.partidos.length > 0 ? (
            <ul>
              {user.partidos.map((partido) => (
                <li key={partido.id}>
                  <p>
                    <strong>{partido.nombre}</strong> - {new Date(partido.fecha).toLocaleDateString()}
                  </p>
                </li>
              ))}
            </ul>
          ) : (
            <p>No hay partidos registrados.</p>
          )}
        </div>

        {/* Calificaciones */}
        <div className="card seccion">
          <h3>Calificaciones</h3>
          {user.calificaciones.length > 0 ? (
            <ul>
              {user.calificaciones.map((calificacion) => (
                <li key={calificacion.id}>
                  <p>
                    <strong>{calificacion.calificator}:</strong> {calificacion.puntuacion}/5
                  </p>
                  <p>{calificacion.comentario}</p>
                </li>
              ))}
            </ul>
          ) : (
            <p>No tienes calificaciones aún.</p>
          )}
        </div>

        {/* Reportes */}
        <div className="card seccion">
          <h3>Reportes</h3>
          {user.reportes.length > 0 ? (
            <ul>
              {user.reportes.map((reporte) => (
                <li key={reporte.id}>
                  <p>
                    <strong>Motivo:</strong> {reporte.motivo}
                  </p>
                  <p>{reporte.descripcion}</p>
                  <p>
                    <strong>Estado:</strong> {reporte.estado}
                  </p>
                </li>
              ))}
            </ul>
          ) : (
            <p>No tienes reportes.</p>
          )}
        </div>

        {/* Sanciones */}
        <div className="card seccion">
          <h3>Sanciones</h3>
          {user.sanciones.length > 0 ? (
            <ul>
              {user.sanciones.map((sancion) => (
                <li key={sancion.id}>
                  <p>
                    <strong>Motivo:</strong> {sancion.motivo}
                  </p>
                  <p>{sancion.descripcion}</p>
                  <p>
                    <strong>Tipo de Sanción:</strong> {sancion.tipo_sancion}
                  </p>
                </li>
              ))}
            </ul>
          ) : (
            <p>No tienes sanciones.</p>
          )}
        </div>

        {/* Notificaciones */}
        <div className="card seccion">
          <h3>Notificaciones</h3>
          {user.notificaciones.length > 0 ? (
            <ul>
              {user.notificaciones.map((notificacion) => (
                <li key={notificacion.id}>
                  <p>
                    <strong>{notificacion.tipo}:</strong> {notificacion.mensaje}
                  </p>
                </li>
              ))}
            </ul>
          ) : (
            <p>No tienes notificaciones.</p>
          )}
        </div>

        {/* Citas (Solo para Arrendadores) */}
        {user.tipo_usuario === 'Arrendador' && (
          <div className="card seccion">
            <h3>Citas Programadas</h3>
            {user.citas.length > 0 ? (
              <ul>
                {user.citas.map((cita) => (
                  <li key={cita.id}>
                    <p>
                      <strong>Fecha y Hora:</strong> {new Date(cita.fecha_hora).toLocaleString()}
                    </p>
                    <p>{cita.descripcion}</p>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No tienes citas programadas.</p>
            )}
          </div>
        )}

        {/* Futuras Implementaciones: Chats */}
        <div className="card seccion">
          <h3>Chats</h3>
          <p>
            Próximamente podrás acceder a diferentes tipos de chat para comunicarte con otros
            usuarios.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;