import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode'; // Corrección en la importación de jwt-decode
import '../EditarPerfil/EditarPerfil.css'; // Importación correcta del CSS
import { Form, Button } from 'react-bootstrap'; // Usar componentes de Bootstrap para el diseño
import './EditarPerfilMobile.css'; // Asegúrate de tener este archivo si es necesario

const EditarPerfil = () => {
  const navigate = useNavigate();
  const [originalValues, setOriginalValues] = useState({});
  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [correo, setCorreo] = useState('');
  const [edad, setEdad] = useState('');
  const [ubicacion_preferida_id, setUbicacionPreferidaId] = useState(null);
  const [claveAnterior, setClaveAnterior] = useState(''); // Estado para la clave anterior
  const [nuevaClave, setNuevaClave] = useState(''); // Estado para la nueva clave
  const [confirmarNuevaClave, setConfirmarNuevaClave] = useState(''); // Estado para confirmar la nueva clave
  const [showPassword, setShowPassword] = useState(false); // Mostrar/ocultar clave anterior
  const [showNewPassword, setShowNewPassword] = useState(false); // Mostrar/ocultar nueva clave
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // Mostrar/ocultar confirmación de nueva clave
  const [passwordError, setPasswordError] = useState(''); // Error en la nueva contraseña
  const [confirmPasswordError, setConfirmPasswordError] = useState(''); // Error en la confirmación de la nueva contraseña
  const [esGoogleUser, setEsGoogleUser] = useState(false); // Estado para identificar usuarios de Google
  const [tieneClaveAnterior, setTieneClaveAnterior] = useState(false); // Estado para saber si el usuario tiene clave anterior

  const passwordPattern = /^(?=.*[A-Z])(?=.*\d|.*[\W_]).{6,}$/; // Patrón de validación de contraseña

  // Definir la URL del backend
  const backendUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001';

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decoded = jwtDecode(token); // Decodificar el token JWT
        setOriginalValues({
          nombre: decoded.nombre || '',
          apellido: decoded.apellido || '',
          correo: decoded.correo || '',
          edad: decoded.edad || '',
          ubicacion_preferida_id: decoded.ubicacion_preferida_id || null,
        });
        setNombre(decoded.nombre || '');
        setApellido(decoded.apellido || '');
        setCorreo(decoded.correo || '');
        setEdad(decoded.edad || '');
        setUbicacionPreferidaId(decoded.ubicacion_preferida_id || null);
        setEsGoogleUser(!!decoded.google_id); // Si tiene google_id, es usuario de Google

        // Verificar si el usuario tiene clave anterior en la base de datos
        fetch(`${backendUrl}protected/perfil`, { 
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        })
          .then(response => response.json())
          .then(data => {
            setTieneClaveAnterior(data.tieneClave); // Si tiene clave, true; de lo contrario, false
          })
          .catch(error => {
            console.error('Error al obtener la información del usuario:', error);
            alert('Error al cargar la información del usuario.');
            navigate('/'); // Redirigir al inicio si hay un error
          });
      } catch (error) {
        console.error('Error al decodificar el token:', error);
        alert('Token inválido o expirado. Por favor, inicia sesión nuevamente.');
        navigate('/'); // Redirigir al inicio si hay un error
      }
    } else {
      alert('No estás autenticado. Por favor, inicia sesión.');
      navigate('/'); // Redirigir al inicio si no hay token
    }
  }, [navigate, backendUrl]);

  const validatePassword = (password) => {
    if (!passwordPattern.test(password)) {
      return 'La contraseña debe tener al menos 6 caracteres y contener al menos 1 número, 1 mayúscula o 1 carácter especial.';
    }
    return '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    setPasswordError('');
    setConfirmPasswordError('');

    // Validación de la contraseña si se desea cambiar
    if (nuevaClave || confirmarNuevaClave) {
      if (!claveAnterior && tieneClaveAnterior) {
        alert('Por favor, ingresa tu clave anterior.');
        return;
      }

      // Validar nueva clave y su confirmación
      const passwordValidationError = validatePassword(nuevaClave);
      if (passwordValidationError) {
        setPasswordError(passwordValidationError);
        return;
      }

      if (nuevaClave !== confirmarNuevaClave) {
        setConfirmPasswordError('Las contraseñas no coinciden.');
        return;
      }
    }

    // Crear objeto solo con campos modificados
    const updatedFields = {};
    if (nombre !== originalValues.nombre) updatedFields.nombre = nombre;
    if (apellido !== originalValues.apellido) updatedFields.apellido = apellido;
    if (edad !== originalValues.edad) updatedFields.edad = edad;
    if (ubicacion_preferida_id !== originalValues.ubicacion_preferida_id) {
      updatedFields.ubicacion_preferida_id = ubicacion_preferida_id;
    }
    
    // Si se está cambiando la clave, agregarla al objeto updatedFields
    if (nuevaClave) {
      updatedFields.claveAnterior = claveAnterior;
      updatedFields.nuevaClave = nuevaClave;
    }

    // Verificar si hay campos para actualizar
    if (Object.keys(updatedFields).length === 0) {
      alert('No hay cambios para actualizar.');
      return;
    }

    try {
      const res = await fetch(`${backendUrl}protected/editar-perfil`, { // Ruta protegida
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Enviar el token JWT
        },
        body: JSON.stringify(updatedFields), // Enviar solo los campos modificados
      });

      const data = await res.json();

      if (res.ok) {
        alert('Perfil actualizado exitosamente.');
        setOriginalValues((prev) => ({
          ...prev,
          ...updatedFields,
        }));
        navigate('/dashboard'); // Redirigir al dashboard (opcional)
      } else {
        alert(data.error || 'Error al actualizar el perfil.');
      }
    } catch (error) {
      console.error('Error al actualizar el perfil:', error);
      alert('Error al actualizar el perfil.');
    }
  };

  return (
    <div className="editar-perfil-container mt-5">
      <h2>Editar Perfil</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formNombre">
          <Form.Label>Nombre</Form.Label>
          <Form.Control
            type="text"
            placeholder="Ingrese su nombre"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formApellido">
          <Form.Label>Apellido</Form.Label>
          <Form.Control
            type="text"
            placeholder="Ingrese su apellido"
            value={apellido}
            onChange={(e) => setApellido(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formEdad">
          <Form.Label>Edad</Form.Label>
          <Form.Control
            type="number"
            placeholder="Ingrese su edad"
            value={edad}
            onChange={(e) => setEdad(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formCorreo">
          <Form.Label>Correo Electrónico</Form.Label>
          <Form.Control
            type="email"
            value={correo}
            readOnly // Campo de correo electrónico es solo lectura
          />
        </Form.Group>
        
        {/* Mostrar campos de contraseña solo si el usuario no es de Google */}
        {!esGoogleUser && tieneClaveAnterior && (
          <Form.Group controlId="formClaveAnterior">
            <Form.Label>Clave Anterior</Form.Label>
            <Form.Control
              type={showPassword ? 'text' : 'password'}
              placeholder="Ingrese su clave anterior"
              value={claveAnterior}
              onChange={(e) => setClaveAnterior(e.target.value)}
            />
            <Button variant="link" onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? 'Ocultar' : 'Mostrar'}
            </Button>
          </Form.Group>
        )}
        {/* Mostrar campos para nueva clave y confirmar nueva clave */}
        {!esGoogleUser && (
          <>
            <Form.Group controlId="formNuevaClave">
              <Form.Label>Nueva Clave</Form.Label>
              <Form.Control
                type={showNewPassword ? 'text' : 'password'}
                placeholder="Ingrese su nueva clave"
                value={nuevaClave}
                onChange={(e) => {
                  setNuevaClave(e.target.value);
                  setPasswordError(validatePassword(e.target.value));
                }}
              />
              <Button variant="link" onClick={() => setShowNewPassword(!showNewPassword)}>
                {showNewPassword ? 'Ocultar' : 'Mostrar'}
              </Button>
              {passwordError && <Form.Text className="form-text text-danger">{passwordError}</Form.Text>}
            </Form.Group>

            <Form.Group controlId="formConfirmarNuevaClave">
              <Form.Label>Confirmar Nueva Clave</Form.Label>
              <Form.Control
                type={showConfirmPassword ? 'text' : 'password'}
                placeholder="Confirme su nueva clave"
                value={confirmarNuevaClave}
                onChange={(e) => {
                  setConfirmarNuevaClave(e.target.value);
                  setConfirmPasswordError(e.target.value !== nuevaClave ? 'Las contraseñas no coinciden.' : '');
                }}
              />
              <Button variant="link" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                {showConfirmPassword ? 'Ocultar' : 'Mostrar'}
              </Button>
              {confirmPasswordError && <Form.Text className="text-danger">{confirmPasswordError}</Form.Text>}
            </Form.Group>
          </>
        )}

        <Button variant="primary" type="submit">
          Actualizar Perfil
        </Button>
      </Form>
    </div>
  );
};

export default EditarPerfil;
