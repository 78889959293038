import React, { useState, useCallback } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './RegisterModal.css';
import GoogleLoginButton from '../GoogleLoginButton/GoogleLoginButton';

const RegisterModal = ({ show, handleClose }) => {
  const [formData, setFormData] = useState({
    nombre: '',
    apellido: '',
    correo: '',
    contraseña: '',
    confirmarContraseña: '',
    edad: '',
  });

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const backendUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001'; // URL desde .env

  const validatePassword = useCallback((password) => {
    const passwordPattern = /^(?=.*[A-Z])(?=.*\d|.*[\W_]).{6,}$/;
    if (!passwordPattern.test(password)) {
      return 'La contraseña debe tener al menos 6 caracteres, con al menos 1 número, 1 mayúscula o 1 carácter especial.';
    }
    return '';
  }, []);

  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));

      if (name === 'contraseña') {
        const passwordError = validatePassword(value);
        setError(passwordError);
      }

      if (name === 'confirmarContraseña') {
        setError(
          value !== formData.contraseña ? 'Las contraseñas no coinciden.' : ''
        );
      }
    },
    [formData.contraseña, validatePassword]
  );

  const resetForm = useCallback(() => {
    setFormData({
      nombre: '',
      apellido: '',
      correo: '',
      contraseña: '',
      confirmarContraseña: '',
      edad: '',
    });
    setError(null);
    setSuccess(false);
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      setError(null);
      setSuccess(false);

      const passwordValidationError = validatePassword(formData.contraseña);
      if (
        passwordValidationError ||
        formData.contraseña !== formData.confirmarContraseña
      ) {
        setError(
          passwordValidationError || 'Las contraseñas no coinciden.'
        );
        setLoading(false);
        return;
      }

      console.log('Backend URL:', backendUrl);

      try {
        const response = await fetch(`${backendUrl}auth/register`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });

        if (!response.ok) {
          const errorData = await response.text();
          console.error('Error de respuesta:', errorData);
          setError('Error al registrar el usuario.');
          setLoading(false);
          return;
        }

        const result = await response.json();
        console.log('Registro exitoso:', result);

        setSuccess(true);
        resetForm();
      } catch (err) {
        console.error('Error de conexión:', err);
        setError('Error de conexión. No se pudo completar el registro.');
      } finally {
        setLoading(false);
      }
    },
    [formData, resetForm, validatePassword, backendUrl]
  );

  const togglePasswordVisibility = useCallback(
    () => setShowPassword((prev) => !prev),
    []
  );

  const toggleConfirmPasswordVisibility = useCallback(
    () => setShowConfirmPassword((prev) => !prev),
    []
  );

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Registro de Usuario</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">¡Registro exitoso!</Alert>}
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formNombre">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingrese su nombre"
              name="nombre"
              value={formData.nombre}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formApellido">
            <Form.Label>Apellido</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingrese su apellido"
              name="apellido"
              value={formData.apellido}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formCorreo">
            <Form.Label>Correo</Form.Label>
            <Form.Control
              type="email"
              placeholder="Ingrese su correo"
              name="correo"
              value={formData.correo}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formContraseña" className="password-group">
            <Form.Label>Contraseña</Form.Label>
            <div className="d-flex">
              <Form.Control
                type={showPassword ? 'text' : 'password'}
                placeholder="Ingrese su contraseña"
                name="contraseña"
                value={formData.contraseña}
                onChange={handleChange}
                required
              />
              <Button variant="link" onClick={togglePasswordVisibility}>
                {showPassword ? 'Ocultar' : 'Mostrar'}
              </Button>
            </div>
            {error && formData.contraseña && (
              <Form.Text className="text-danger">{error}</Form.Text>
            )}
          </Form.Group>
          <Form.Group
            controlId="formConfirmarContraseña"
            className="password-group"
          >
            <Form.Label>Confirmar Contraseña</Form.Label>
            <div className="d-flex">
              <Form.Control
                type={showConfirmPassword ? 'text' : 'password'}
                placeholder="Confirme su contraseña"
                name="confirmarContraseña"
                value={formData.confirmarContraseña}
                onChange={handleChange}
                required
              />
              <Button variant="link" onClick={toggleConfirmPasswordVisibility}>
                {showConfirmPassword ? 'Ocultar' : 'Mostrar'}
              </Button>
            </div>
            {error && formData.confirmarContraseña && (
              <Form.Text className="text-danger">{error}</Form.Text>
            )}
          </Form.Group>
          <Form.Group controlId="formEdad">
            <Form.Label>Edad</Form.Label>
            <Form.Control
              type="number"
              placeholder="Ingrese su edad"
              name="edad"
              value={formData.edad}
              onChange={handleChange}
            />
          </Form.Group>
          <Button
            variant="primary"
            type="submit"
            disabled={loading}
            className="w-100 mt-3"
          >
            {loading ? 'Registrando...' : 'Registrarse'}
          </Button>
          <div className="text-center mt-3">o</div>
          <div className="text-center mt-3">
            <GoogleLoginButton onLoginSuccess={handleClose} />
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default RegisterModal;
