// src/App.js
import React from 'react';
import NavbarMain from './components/Navbar/NavbarMain';
import { AuthProvider } from './contexts/AuthContext';
import AppRoutes from './AppRoutes';
import { HelmetProvider } from 'react-helmet-async'; // Importar HelmetProvider
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

const App = () => {
  return (
    <HelmetProvider> {/* Envolver toda la aplicación con HelmetProvider */}
      <AuthProvider> {/* Envolver toda la aplicación con AuthProvider */}
        <NavbarMain />
        <div className="container">
          <AppRoutes />
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          pauseOnHover
        />
      </AuthProvider>
    </HelmetProvider>
  );
};

export default App;
