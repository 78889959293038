// src/components/Pages/Productos/ProductosClientes.jsx
import React, { useEffect, useState } from 'react';
import ModalProducto from '../../ModalProducto/ModalProducto.jsx';
import './ProductosClientes.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const ProductosClientes = () => {
  const [productos, setProductos] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [filtros, setFiltros] = useState({
    categorias: [],
    marcas: [],
    orden: 'default',
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [modalProducto, setModalProducto] = useState(null);
  const [expandido, setExpandido] = useState({});
  const [filtrosExpandido, setFiltrosExpandido] = useState({
    categorias: false,
    marcas: false,
  });

  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api/';
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams(); // Obtener el parámetro :id de la URL

  const getQueryParams = () => {
    return new URLSearchParams(location.search);
  };

  // Cargar Categorías
  useEffect(() => {
    const fetchCategorias = async () => {
      try {
        const response = await fetch(`${API_URL}categorias/conteo`);
        if (!response.ok) throw new Error('Error al obtener las categorías');
        const data = await response.json();
        setCategorias(data.filter((categoria) => categoria.conteo > 0));
      } catch (error) {
        console.error('Error al cargar categorías:', error);
        setCategorias([]);
      }
    };

    fetchCategorias();
  }, [API_URL]);

  // Cargar Marcas
  useEffect(() => {
    const fetchMarcas = async () => {
      try {
        const response = await fetch(`${API_URL}marcas/conteo`);
        if (!response.ok) throw new Error('Error al obtener las marcas');
        const data = await response.json();
        setMarcas(data.filter((marca) => marca.conteo > 0));
      } catch (error) {
        console.error('Error al cargar marcas:', error);
        setMarcas([]);
      }
    };

    fetchMarcas();
  }, [API_URL]);

  // Leer parámetros de la URL (categoría o marca) y aplicarlos a los filtros
  useEffect(() => {
    const queryParams = getQueryParams();
    const categoriaParam = queryParams.getAll('categoria');
    const marcaParam = queryParams.getAll('marca');

    let nuevasCategorias = categoriaParam.map(Number);
    let nuevasMarcas = marcaParam.map(Number);

    setFiltros((prev) => ({
      ...prev,
      categorias: nuevasCategorias,
      marcas: nuevasMarcas
    }));

    setFiltrosExpandido({
      categorias: nuevasCategorias.length > 0,
      marcas: nuevasMarcas.length > 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  // Cargar Productos con Filtros
  useEffect(() => {
    const fetchProductos = async () => {
      setLoading(true);
      setError(false);

      try {
        const queryParams = new URLSearchParams();

        if (filtros.categorias.length > 0 && !filtros.categorias.includes(0)) {
          filtros.categorias.forEach((categoriaId) => {
            queryParams.append('categoria', categoriaId);
          });
        }

        if (filtros.marcas.length > 0) {
          filtros.marcas.forEach((marcaId) => {
            queryParams.append('marca', marcaId);
          });
        }

        if (filtros.orden !== 'default') {
          queryParams.append('orden', filtros.orden);
        }

        const response = await fetch(`${API_URL}productos?${queryParams.toString()}`);
        if (!response.ok) throw new Error('Error al obtener los productos');
        const data = await response.json();
        setProductos(data);
      } catch (error) {
        console.error('Error al cargar productos:', error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchProductos();
  }, [API_URL, filtros]);

  // Abrir modal si se seleccionó un producto (/:id)
  useEffect(() => {
    if (id) {
      const productoId = parseInt(id, 10);
      const productoSeleccionado = productos.find((producto) => producto.id === productoId);

      if (productoSeleccionado) {
        abrirModal(productoSeleccionado);
        // No navegamos de vuelta aquí para mantener el :id en la URL
      } else {
        // Buscar el producto si no está en la lista actual
        const fetchProducto = async () => {
          try {
            const response = await fetch(`${API_URL}productos/${productoId}`);
            if (!response.ok) throw new Error('Error al obtener el producto');
            const data = await response.json();
            abrirModal(data);
            // No navegamos de vuelta aquí para mantener el :id en la URL
          } catch (error) {
            console.error('Error al cargar el producto seleccionado:', error);
          }
        };

        fetchProducto();
      }
    } else {
      // Cerrar el modal si no hay id en la URL
      cerrarModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, productos, API_URL, navigate]);

  // Verificar si el producto del modal sigue en la lista filtrada
  useEffect(() => {
    if (!loading && modalProducto) {
      const productoEnLista = productos.find(
        (producto) => producto.id === modalProducto.id
      );

      if (!productoEnLista) { 
        cerrarModal();
        alert('El producto seleccionado ya no está disponible bajo los filtros actuales.');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productos, modalProducto, loading]);

  // Manejar cambios en los checkboxes
  const handleCheckboxChange = (e, tipo) => {
    const { value, checked } = e.target;
    const val = parseInt(value, 10);

    setFiltros((prev) => {
      const nuevosFiltros = checked
        ? [...prev[tipo], val]
        : prev[tipo].filter((itemId) => itemId !== val);

      return { ...prev, [tipo]: nuevosFiltros };
    });
  };

  // Manejar cambios en el orden
  const handleOrdenChange = (e) => {
    setFiltros((prev) => ({ ...prev, orden: e.target.value }));
  };

  // Abrir modal de producto
  const abrirModal = (producto) => setModalProducto(producto);

  // Cerrar modal de producto y navegar a /productos con los search params si 'id' estaba presente
  const cerrarModal = () => {
    setModalProducto(null);
    if (id) { // Solo navegar si 'id' estaba presente
      navigate(`/productos${location.search}`, { replace: true });
    }
  };

  // Alternar descripción expandida
  const toggleDescripcion = (id) => {
    setExpandido((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  // Alternar expansión de filtros
  const toggleFiltros = (tipo) => {
    setFiltrosExpandido((prev) => ({
      ...prev,
      [tipo]: !prev[tipo],
    }));
  };

  // Resetear filtros
  const resetFiltros = () => {
    setFiltros({
      categorias: [],
      marcas: [],
      orden: 'default',
    });
    setFiltrosExpandido({
      categorias: false,
      marcas: false,
    });
    navigate('/productos', { replace: true });
  };

  // Sincronizar filtros con la URL cada vez que cambian
  useEffect(() => {
    const queryParams = new URLSearchParams();

    if (filtros.categorias.length > 0 && !filtros.categorias.includes(0)) {
      filtros.categorias.forEach((categoriaId) => {
        queryParams.append('categoria', categoriaId);
      });
    }

    if (filtros.marcas.length > 0) {
      filtros.marcas.forEach((marcaId) => {
        queryParams.append('marca', marcaId);
      });
    }

    if (filtros.orden !== 'default') {
      queryParams.append('orden', filtros.orden);
    }

    // Evitar navegar si los queryParams actuales ya corresponden con filtros
    const currentQuery = location.search.replace('?', '');
    const newQuery = queryParams.toString();

    if (currentQuery !== newQuery) {
      navigate(`/productos?${queryParams.toString()}`, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtros.categorias, filtros.marcas, filtros.orden]);

  return (
    <div className="productos-clientes-container">
      <div className="productos-clientes-filtros">
        {/* Filtro de Orden */}
        <div className="productos-clientes-orden-precio">
          <label htmlFor="orden">Ordenar por:</label>
          <select name="orden" id="orden" value={filtros.orden} onChange={handleOrdenChange}>
            <option value="default">Sin ordenar</option>
            <option value="asc">Precio Ascendente</option>
            <option value="desc">Precio Descendente</option>
          </select>
        </div>

        {/* Filtros de Categorías */}
        {categorias.length > 0 && (
          <div className={`productos-clientes-filtro-categorias ${filtrosExpandido.categorias ? 'expandido' : 'minimizado'}`}>
            <h3 onClick={() => toggleFiltros('categorias')}>
              Categorías
              <span className="expandir-icon">{filtrosExpandido.categorias ? '▲' : '▼'}</span>
            </h3>
            {filtrosExpandido.categorias && categorias.map(({ id, nombre, conteo }) => (
              <label key={id} className="categoria-checkbox">
                <input
                  type="checkbox"
                  value={id}
                  checked={filtros.categorias.includes(id)}
                  onChange={(e) => handleCheckboxChange(e, 'categorias')}
                />
                {nombre} ({conteo})
              </label>
            ))}
          </div>
        )}

        {/* Filtros de Marcas */}
        {marcas.length > 0 && (
          <div className={`productos-clientes-filtro-marcas ${filtrosExpandido.marcas ? 'expandido' : 'minimizado'}`}>
            <h3 onClick={() => toggleFiltros('marcas')}>
              Marcas
              <span className="expandir-icon">{filtrosExpandido.marcas ? '▲' : '▼'}</span>
            </h3>
            {filtrosExpandido.marcas && marcas.map(({ id, nombre, conteo }) => (
              <label key={id} className="marca-checkbox">
                <input
                  type="checkbox"
                  value={id}
                  checked={filtros.marcas.includes(id)}
                  onChange={(e) => handleCheckboxChange(e, 'marcas')}
                />
                {nombre} ({conteo})
              </label>
            ))}
          </div>
        )}

        {/* Opciones Adicionales */}
        <div className="productos-clientes-opciones-adicionales">
          <button className="reset-filtros-button" onClick={resetFiltros}>
            Resetear Filtros
          </button>
        </div>
      </div>

      <div className="productos-clientes-grid">
        {loading ? (
          <div className="productos-clientes-loading">Cargando productos...</div>
        ) : error ? (
          <div className="productos-clientes-error">Ocurrió un error al cargar los productos. Intenta nuevamente más tarde.</div>
        ) : productos.length === 0 ? (
          <div className="productos-clientes-no-results">No se encontraron productos.</div>
        ) : (
          productos.map((producto) => (
            <div
              key={producto.id}
              className="productos-clientes-producto-card"
              onClick={() => abrirModal(producto)}
            >
              <img
                src={`${API_URL}productos/imagenes/${producto.imagen}`}
                alt={producto.nombre}
                loading="lazy"
              />
              <h3 className="productos-clientes-producto-nombre">{producto.nombre}</h3>
              <p className="productos-clientes-producto-precio">${producto.precio}</p>
              <p
                className={`productos-clientes-producto-descripcion ${
                  expandido[producto.id] ? 'expandido' : ''
                }`}
              >
                {producto.descripcion}
              </p>
              <button
                className="productos-clientes-expandir-descripcion"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleDescripcion(producto.id);
                }}
              >
                {expandido[producto.id] ? 'Ver menos' : 'Ver más'}
              </button>
              <p className="productos-clientes-precio-nota">
                * Los precios pueden variar. Consulte en la tienda.
              </p>
            </div>
          ))
        )}
      </div>

      {modalProducto && <ModalProducto producto={modalProducto} cerrarModal={cerrarModal} />}
    </div>
  );

};

export default ProductosClientes;
