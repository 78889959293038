import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import '../GoogleLoginButton/GoogleLoginButton.css';
import { useAuth } from '../../contexts/AuthContext';
import {jwtDecode} from 'jwt-decode'; // Importar jwt-decode correctamente

const GoogleLoginButton = ({ onLoginSuccess }) => {
  const { login } = useAuth(); // Utiliza la función login del contexto de autenticación

  const backendUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001'; // URL del backend desde .env

  const handleLogin = async (response) => {
    const { credential } = response;

    try {
      // Enviar el token de Google al backend para su verificación
      const res = await fetch(`${backendUrl}auth/google`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: credential }),
      });

      const data = await res.json();

      if (res.ok && data.token) {
        // Opcional: Decodificar el token si necesitas información adicional
        const decodedToken = jwtDecode(data.token);
        const expirationTime = decodedToken.exp;
        const expirationDate = new Date(expirationTime * 1000);

        // Verificar si los datos del usuario están presentes
        if (!data.user) {
          alert('No se recibieron los datos del usuario. Contacta al administrador.');
          return;
        }

        // Llamar a la función login del contexto para almacenar los datos globalmente
        login({ token: data.token, user: data.user });
        onLoginSuccess(); // Cierra el modal y redirige al dashboard
      } else {
        alert(data.error || 'Error al iniciar sesión con Google.');
      }
    } catch (error) {
      console.error('Error en la autenticación:', error);
      alert('Error en la autenticación.');
    }
  };

  return (
    <div className="google-login-container">
      <GoogleLogin
        onSuccess={handleLogin}
        onError={() => alert('Error al iniciar sesión con Google.')}
      />
    </div>
  );
};

export default GoogleLoginButton;
