// src/components/ventas/HistorialMovimientos.js

import React from 'react';

const HistorialMovimientos = () => {
  return (
    <div>
      <h2>Historial de Movimientos</h2>
      <p>Aquí puedes ver el historial de movimientos de productos.</p>
      {/* Añade tablas, filtros u otros componentes aquí */}
    </div>
  );
};

export default HistorialMovimientos;
