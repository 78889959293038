// src/components/ventas/ProductosAdmin.jsx

import React, { useEffect, useState, useCallback } from 'react';
import DropdownWithCheckbox from './DropdownWithCheckbox';
import AgregarCategoria from '../AgregarCategoria/AgregarCategoria';
import AgregarMarca from '../AgregarMarca/AgregarMarca';
import CategoriasAdmin from './CategoriasAdmin';
import MarcasAdmin from './MarcasAdmin';
import ListaProductos from './ListaProductos';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import debounce from 'lodash.debounce';
import './ProductosAdmin.css';

const ProductosAdmin = () => {
  // Estados para productos, nuevo producto, categorías y marcas
  const [productos, setProductos] = useState([]);
  const [nuevoProducto, setNuevoProducto] = useState({
    nombre: '',
    descripcion: '',
    descripcion_larga: '',
    precio: '',
    stock: '',
    imagen: null,
    categoria_ids: [],
    marca_id: '',
  });
  const [categorias, setCategorias] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [exists, setExists] = useState(false);
  const [checking, setChecking] = useState(false);

  // Configuración de la API y token
  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api/';
  const token = localStorage.getItem('token');

  // Función para manejar el error 429 (Too Many Requests) con reintentos exponenciales
  const retryRequest = async (url, options, retries = 3, initialDelay = 1000) => {
    let attempt = 0;
    let delay = initialDelay;

    const delayRetry = (ms) => new Promise(res => setTimeout(res, ms));

    while (attempt < retries) {
      try {
        const response = await fetch(url, options);
        if (response.status === 429) {
          attempt++;
          console.warn(`Demasiadas solicitudes, reintentando en ${delay / 1000} segundos...`);
          await delayRetry(delay);
          delay *= 2;
        } else {
          return response;
        }
      } catch (error) {
        throw new Error('Error al realizar la solicitud.');
      }
    }
    throw new Error('Máximo número de reintentos alcanzado');
  };

  // Funciones para obtener productos, categorías y marcas
  const fetchProductos = useCallback(async () => {
    try {
      const response = await retryRequest(`${API_URL}productos`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Error al obtener los productos');
      }
      const data = await response.json();
      setProductos(data);
    } catch (error) {
      toast.error('No se pudieron cargar los productos.');
      console.error(error);
    }
  }, [API_URL, token]);

  const fetchCategorias = useCallback(async () => {
    try {
      const response = await retryRequest(`${API_URL}categorias`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Error al obtener las categorías');
      }
      const data = await response.json();
      setCategorias(data);
    } catch (error) {
      toast.error('No se pudieron cargar las categorías.');
      console.error(error);
    }
  }, [API_URL, token]);

  const fetchMarcas = useCallback(async () => {
    try {
      const response = await retryRequest(`${API_URL}marcas`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Error al obtener las marcas');
      }
      const data = await response.json();
      setMarcas(data);
    } catch (error) {
      toast.error('No se pudieron cargar las marcas.');
      console.error(error);
    }
  }, [API_URL, token]);

  // Hook para obtener datos al montar el componente
  useEffect(() => {
    fetchProductos();
    fetchCategorias();
    fetchMarcas();
  }, [fetchProductos, fetchCategorias, fetchMarcas]);

  // Función para verificar si el producto ya existe (case-insensitive) con debounce
  const verificarExistenciaProducto = useCallback(
    debounce(async (nombre) => {
      if (!nombre.trim()) {
        setExists(false);
        return;
      }

      setChecking(true);
      try {
        const response = await fetch(`${API_URL}productos`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();

          // Verificar coincidencias exactas ignorando mayúsculas/minúsculas
          const nombreLower = nombre.trim().toLowerCase();
          const existeProducto = data.some(
            (producto) => producto.nombre.trim().toLowerCase() === nombreLower
          );

          if (existeProducto) {
            setExists(true);
            toast.warn(`El producto "${nombre}" ya existe.`);
          } else {
            setExists(false);
          }
        } else {
          console.error('Error al verificar la existencia del producto.');
        }
      } catch (error) {
        console.error('Error al verificar la existencia del producto:', error);
      } finally {
        setChecking(false);
      }
    }, 500),
    [API_URL, token]
  );

  // Hook para verificar la existencia del producto cada vez que cambia el nombre
  useEffect(() => {
    verificarExistenciaProducto(nuevoProducto.nombre);
    return () => verificarExistenciaProducto.cancel(); // Limpiar el debounce
  }, [nuevoProducto.nombre, verificarExistenciaProducto]);

  // Función para manejar cambios en los campos de entrada
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNuevoProducto({ ...nuevoProducto, [name]: value });
  };

  // Función para manejar cambios en el campo de imagen
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setNuevoProducto({ ...nuevoProducto, imagen: file });
  };

  // Función para agregar un nuevo producto
  const handleAgregarProducto = async () => {
    const { nombre, descripcion, descripcion_larga, precio, stock, imagen, categoria_ids, marca_id } = nuevoProducto;

    // Validación básica
    if (!nombre.trim() || !precio || !stock || !marca_id || categoria_ids.length === 0) {
      toast.error('Por favor, completa todos los campos obligatorios y selecciona al menos una categoría.');
      return;
    }

    const formData = new FormData();
    Object.keys(nuevoProducto).forEach((key) => {
      if (
        nuevoProducto[key] !== '' &&
        nuevoProducto[key] !== null &&
        nuevoProducto[key] !== undefined &&
        (key !== 'imagen' || (key === 'imagen' && nuevoProducto.imagen))
      ) {
        if (key === 'categoria_ids') {
          // Asegurar que los IDs son números antes de enviarlos
          nuevoProducto.categoria_ids.forEach(id => formData.append('categoria_ids', Number(id)));
        } else {
          formData.append(key, nuevoProducto[key]);
        }
      }
    });

    try {
      const response = await fetch(`${API_URL}productos`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          // No establecer 'Content-Type' cuando se usa FormData
        },
        body: formData,
      });

      const data = await response.json();

      if (response.ok) {
        setNuevoProducto({
          nombre: '',
          descripcion: '',
          descripcion_larga: '',
          precio: '',
          stock: '',
          imagen: null,
          categoria_ids: [],
          marca_id: '',
        });
        fetchProductos();
        toast.success('Producto agregado exitosamente.');
      } else {
        if (data.errors) {
          const mensajes = data.errors.map((err) => err.msg).join('. ');
          toast.error(mensajes);
        } else {
          toast.error(data.error || 'Error al agregar el producto.');
        }
      }
    } catch (error) {
      toast.error('Error al agregar el producto.');
      console.error(error);
    }
  };

  return (
    <div className="productos-admin-container">
      <h2>Gestión de Productos</h2>

      {/* Formulario para Agregar Nuevo Producto */}
      <div className="productos-admin-formulario-agregar">
        <h3>Agregar Nuevo Producto</h3>
        
        {/* Fila 1: Nombre y Precio */}
        <div className="productos-admin-form-row">
          <div className="productos-admin-form-group">
            <label htmlFor="nombre">Nombre:</label>
            <input
              type="text"
              id="nombre"
              name="nombre"
              placeholder="Nombre"
              value={nuevoProducto.nombre}
              onChange={handleInputChange}
            />
          </div>
          <div className="productos-admin-form-group">
            <label htmlFor="precio">Precio:</label>
            <input
              type="number"
              id="precio"
              name="precio"
              placeholder="Precio"
              value={nuevoProducto.precio}
              onChange={handleInputChange}
            />
          </div>
        </div>

        {/* Fila 2: Marca y Imagen */}
        <div className="productos-admin-form-row">
          <div className="productos-admin-form-group">
            <label htmlFor="marca_id">Marca:</label>
            <DropdownWithCheckbox
              label=""
              options={marcas.map(marca => ({
                id: marca.id,
                nombre: marca.nombre,
              }))}
              selectedOptions={nuevoProducto.marca_id ? [Number(nuevoProducto.marca_id)] : []}
              setSelectedOptions={(selected) => {
                const marcaSeleccionada = selected.length > 0 ? Number(selected[0]) : '';
                setNuevoProducto({ ...nuevoProducto, marca_id: marcaSeleccionada });
              }}
              isMultiSelect={false}
              readOnly={false}
            />
          </div>
          <div className="productos-admin-form-group">
            <label htmlFor="imagen">Imagen:</label>
            <input type="file" id="imagen" name="imagen" onChange={handleImageChange} />
          </div>
        </div>

        {/* Fila 3: Descripción y Descripción Larga */}
        <div className="productos-admin-form-row">
          <div className="productos-admin-form-group">
            <label htmlFor="descripcion">Descripción:</label>
            <textarea
              id="descripcion"
              name="descripcion"
              placeholder="Descripción corta"
              value={nuevoProducto.descripcion}
              onChange={handleInputChange}
            ></textarea>
          </div>
          <div className="productos-admin-form-group">
            <label htmlFor="descripcion_larga">Instrucciones:</label>
            <textarea              id="descripcion_larga"
              name="descripcion_larga"
              placeholder="Descripción detallada"
              value={nuevoProducto.descripcion_larga}
              onChange={handleInputChange}
            ></textarea>
          </div>
        </div>

        {/* Fila 4: Categorías */}
        <div className="productos-admin-form-row">
          <div className="productos-admin-form-group">
            <label htmlFor="categoria_ids">Categorías:</label>
            <DropdownWithCheckbox
              label=""
              options={categorias.map(cat => ({
                id: cat.id,
                nombre: cat.nombre,
              }))}
              selectedOptions={nuevoProducto.categoria_ids.map(id => Number(id))}
              setSelectedOptions={(selected) => {
                const categoriasSeleccionadas = selected.map(id => Number(id));
                setNuevoProducto({ ...nuevoProducto, categoria_ids: categoriasSeleccionadas });
              }}
              isMultiSelect={true}
              readOnly={false}
            />
          </div>
        </div>

        {/* Fila 5: Stock, Agregar Nueva Categoría y Agregar Marca */}
        <div className="productos-admin-form-row-extra">
          {/* Cuadro para Stock */}
          <div className="productos-admin-caja-stock">
            <label htmlFor="stock">Stock:</label>
            <input
              type="number"
              id="stock"
              name="stock"
              placeholder="Stock"
              value={nuevoProducto.stock}
              onChange={handleInputChange}
            />
          </div>

         

          <button 
            onClick={handleAgregarProducto} 
            disabled={exists || checking} 
            className="btn-agregar"
          >
            {checking ? 'Verificando...' : 'Agregar Producto'}
          </button>
        </div>
       
      </div>
  {/* Cuadro para Agregar Nueva Categoría y Agregar Marca */}
  <div className="productos-admin-caja-extras">
            <AgregarCategoria API_URL={API_URL} token={token} fetchCategorias={fetchCategorias} />
            <AgregarMarca API_URL={API_URL} token={token} fetchMarcas={fetchMarcas} />
          </div>
      {/* Sección para Gestionar Categorías colapsable */}
      <details className="categorias-collapse">
        <summary>Categorías</summary>
        <CategoriasAdmin 
          API_URL={API_URL} 
          token={token} 
          categorias={categorias}
          fetchCategorias={fetchCategorias} 
        />
      </details>

      {/* Sección para Gestionar Marcas colapsable */}
      <details className="marcas-collapse">
        <summary>Marcas</summary>
        <MarcasAdmin 
          API_URL={API_URL} 
          token={token} 
          marcas={marcas}
          fetchMarcas={fetchMarcas} 
        />
      </details>

      <ListaProductos 
        productos={productos} 
        categorias={categorias} 
        marcas={marcas} 
        API_URL={API_URL} 
        token={token}
        fetchProductos={fetchProductos}
      />
    </div>
  );
};

export default ProductosAdmin;