// src/components/Navbar/NavLinks.jsx

import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom'; // Añadimos useLocation para obtener la ruta actual
import './NavLinks.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faCaretDown } from '@fortawesome/free-solid-svg-icons';

const NavLinks = ({ isAuthenticated, user, handleLogout, openModal }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const location = useLocation(); // Obtenemos la ruta actual

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  // Cerrar el dropdown al hacer clic fuera
  const handleClickOutside = (e) => {
    if (!e.target.closest('.user-container')) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    // Limpieza del listener al desmontar el componente
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  // Comprobamos si estamos en la ruta '/login' para mostrar los botones
  const isLoginRoute = location.pathname === '/login';

  return (
    <div className="nav-links">
      {/* Solo mostrar los botones de login y registro si estamos en '/login' */}
      {isLoginRoute ? (
        <>
          <button className="btn-navbar" onClick={() => openModal('login')}>
            Iniciar Sesión
          </button>
          <button className="btn-navbar btn-primary" onClick={() => openModal('register')}>
            Registrarse
          </button>
        </>
      ) : (
        // Si estamos autenticados, mostramos las opciones de navegación
        isAuthenticated && (
          <>
            <NavLink
              to="/dashboard"
              className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
            >
              Dashboard
            </NavLink>
            {user?.tipo_usuario === 'Arrendador' && (
              <NavLink
                to="/inventario"
                className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
              >
                Inventario
              </NavLink>
            )}
            <div className="user-container">
              <div
                className="user-info"
                onClick={toggleDropdown}
                role="button"
                tabIndex="0"
                aria-haspopup="true"
                aria-expanded={dropdownOpen}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                    toggleDropdown();
                  }
                }}
              >
                <FontAwesomeIcon icon={faUserCircle} className="user-icon" />
                <span className="user-name">{user?.nombre}</span>
                <FontAwesomeIcon
                  icon={faCaretDown}
                  className={`dropdown-icon ${dropdownOpen ? 'open' : ''}`}
                />
              </div>

              {/* Opciones del usuario */}
              <div className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`}>
                <NavLink
                  to="/perfil"
                  className="dropdown-item"
                  onClick={() => setDropdownOpen(false)}
                >
                  Perfil
                </NavLink>
                <NavLink
                  to="/editar-perfil"
                  className="dropdown-item"
                  onClick={() => setDropdownOpen(false)}
                >
                  Editar Perfil
                </NavLink>
                <button className="dropdown-item" onClick={handleLogout}>
                  Cerrar Sesión
                </button>
              </div>
            </div>
          </>
        )
      )}
    </div>
  );
};

export default NavLinks;
