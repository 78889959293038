// src/components/AgregarPuesto/AgregarPuesto.js

import React, { useState } from 'react';
import { createPuesto } from '../Servicios/puestosService';

const AgregarPuesto = ({ onPuestoAdded }) => {
  const [nombre, setNombre] = useState('');
  const [ubicacion, setUbicacion] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [nombrePersona, setNombrePersona] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleAgregarPuesto = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');

    if (!nombre || !ubicacion || !descripcion || !nombrePersona) {
      setErrorMessage('Todos los campos son obligatorios.');
      return;
    }

    const nuevoPuesto = {
      nombre,
      ubicacion,
      descripcion,
      nombrePersona,
    };

    try {
      const puestoCreado = await createPuesto(token, nuevoPuesto);
      onPuestoAdded(puestoCreado);

      setNombre('');
      setUbicacion('');
      setDescripcion('');
      setNombrePersona('');
      setErrorMessage('');
      alert('Puesto agregado exitosamente');
    } catch (error) {
      console.error('Error al agregar el puesto:', error);
      setErrorMessage('No se pudo agregar el puesto.');
    }
  };

  return (
    <form className="agregar-puesto-form" onSubmit={handleAgregarPuesto}>
      <label>Nombre del Puesto:</label>
      <input
        type="text"
        value={nombre}
        onChange={(e) => setNombre(e.target.value)}
      />

      <label>Ubicación:</label>
      <input
        type="text"
        value={ubicacion}
        onChange={(e) => setUbicacion(e.target.value)}
      />

      <label>Descripción:</label>
      <textarea
        value={descripcion}
        onChange={(e) => setDescripcion(e.target.value)}
      ></textarea>

      <label>Nombre de la Persona a Cargo:</label>
      <input
        type="text"
        value={nombrePersona}
        onChange={(e) => setNombrePersona(e.target.value)}
      />

      {errorMessage && <p className="error-text">{errorMessage}</p>}
      <button type="submit" className="btn btn-agregar">
        Agregar Puesto
      </button>
    </form>
  );
};

export default AgregarPuesto;
