// fakeUserData.js

export const initialUser = {
    id: 1,
    nombre: 'Juan',
    apellido: 'Pérez',
    correo: 'juan.perez@example.com',
    telefono: '+34 600 123 456',
    direccion: 'Calle Falsa 123, Madrid, España',
    foto: 'https://via.placeholder.com/150',
    estado: 'Activo',
    edad: 25,
    genero: 'Masculino',
    tipo_usuario: 'Jugador', // Cambia a 'Arrendador' si deseas probar la sección de citas
    idiomas: ['Español', 'Inglés'],
    redes_sociales: {
      twitter: 'https://twitter.com/juanperez',
      facebook: 'https://facebook.com/juanperez',
      linkedin: 'https://linkedin.com/in/juanperez',
      sitio_web: 'https://juanperez.com',
    },
    ubicacion: {
      latitud: null,
      longitud: null,
    },
    partidos: [],
    calificaciones: [],
    reportes: [],
    sanciones: [],
    notificaciones: [],
    citas: [],
    estadisticas: {},
    mensajes: [],
    habilidades: ['Fútbol', 'Tenis', 'Baloncesto', 'Natación', 'Atletismo'],
    fecha_nacimiento: '1999-10-15',
    rol: {
      tipo: 'Delantero',
      descripcion: 'Posición ofensiva encargada de marcar goles y liderar el ataque del equipo.',
    },
  };
  
  export const cargarHistorialPartidos = () => {
    return [
      { id: 1, nombre: 'Partido de la Semana', fecha: '2023-09-01' },
      { id: 2, nombre: 'Partido en la Playa', fecha: '2023-08-25' },
    ];
  };
  
  export const cargarCalificaciones = () => {
    return [
      { id: 1, calificator: 'Ana Gómez', puntuacion: 5, comentario: 'Excelente jugador.' },
      { id: 2, calificator: 'Luis Martínez', puntuacion: 4, comentario: 'Buen desempeño.' },
      { id: 3, calificator: 'María López', puntuacion: 3, comentario: 'Desempeño promedio.' },
      { id: 4, calificator: 'Carlos Ruiz', puntuacion: 4.5, comentario: 'Muy buen juego.' },
      { id: 5, calificator: 'Sofía Díaz', puntuacion: 5, comentario: 'Jugador destacado.' },
    ];
  };
  
  export const cargarReportes = () => {
    return [
      {
        id: 1,
        motivo: 'Comportamiento',
        descripcion: 'Uso de lenguaje inapropiado.',
        estado: 'Pendiente',
      },
    ];
  };
  
  export const cargarSanciones = () => {
    return [
      {
        id: 1,
        motivo: 'Incumplimiento de reglas',
        descripcion: 'Retraso recurrente.',
        tipo_sancion: 'Temporal',
      },
    ];
  };
  
  export const cargarNotificaciones = () => {
    return [
      {
        id: 1,
        mensaje: 'Tienes un nuevo partido programado para mañana.',
        tipo: 'Recordatorio',
      },
      {
        id: 2,
        mensaje: 'Tu reporte ha sido revisado.',
        tipo: 'Información',
      },
    ];
  };
  
  export const cargarCitas = () => {
    return [
      {
        id: 1,
        fecha_hora: '2023-09-10 14:00',
        descripcion: 'Reunión con potencial cliente.',
      },
    ];
  };
  
  export const cargarEstadisticas = () => {
    return {
      total_canchas: 5,
      total_partidos: 20,
      total_inscripciones: 50,
      promedio_puntuacion: 4.2, // Promedio de Puntuación
    };
  };
  