// EmpleadoForm.jsx

import React from 'react';
import './EmpleadoForm.css';

const EmpleadoForm = ({
  nombreEmpleado,
  setNombreEmpleado,
  apellidoEmpleado,
  setApellidoEmpleado,
  correoEmpleado,
  setCorreoEmpleado,
  telefonoEmpleado,
  setTelefonoEmpleado,
  handleSubmitEmpleado,
  resetForm,
  modoEdicion,
}) => {
  return (
    <form onSubmit={handleSubmitEmpleado} className="empleado-form">
      <div className="form-group">
        <label htmlFor="nombre_empleado">Nombre:</label>
        <input
          id="nombre_empleado"
          type="text"
          placeholder="Nombre"
          value={nombreEmpleado}
          onChange={(e) => setNombreEmpleado(e.target.value)}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="apellido_empleado">Apellido:</label>
        <input
          id="apellido_empleado"
          type="text"
          placeholder="Apellido"
          value={apellidoEmpleado}
          onChange={(e) => setApellidoEmpleado(e.target.value)}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="correo_empleado">Correo:</label>
        <input
          id="correo_empleado"
          type="email"
          placeholder="Correo"
          value={correoEmpleado}
          onChange={(e) => setCorreoEmpleado(e.target.value)}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="telefono_empleado">Teléfono:</label>
        <input
          id="telefono_empleado"
          type="text"
          placeholder="Teléfono"
          value={telefonoEmpleado}
          onChange={(e) => setTelefonoEmpleado(e.target.value)}
        />
      </div>

      <div className="form-buttons">
        <button type="submit" className="btn-guardar">
          {modoEdicion ? 'Actualizar Empleado' : 'Guardar Empleado'}
        </button>
        <button type="button" className="btn-cancelar" onClick={resetForm}>
          Cancelar
        </button>
      </div>
    </form>
  );
};

export default EmpleadoForm;
