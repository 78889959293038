// src/components/ventas/Sidebar.js
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Sidebar.css';

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {/* Botón de Toggle fuera del sidebar */}
      <button
        className={`sidebar-toggle-btn ${isOpen ? 'open' : 'closed'}`}
        onClick={toggleSidebar}
        aria-label={isOpen ? 'Cerrar Sidebar' : 'Abrir Sidebar'}
      >
        {isOpen ? <i className="fas fa-chevron-left"></i> : <i className="fas fa-chevron-right"></i>}
      </button>

      {/* Contenedor del Sidebar */}
      <div className={`sidebar-inventario ${isOpen ? 'open' : 'closed'}`}>
        <div className="sidebar">
          <ul className="sidebar-menu">
            <li>
              <NavLink 
                to="/inventario/dashboard" 
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                <i className="fas fa-tachometer-alt"></i>
                {isOpen && <span> Dashboard</span>}
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="/inventario/productos" 
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                <i className="fas fa-boxes"></i>
                {isOpen && <span> Productos</span>}
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="/inventario/historial" 
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                <i className="fas fa-history"></i>
                {isOpen && <span> Historial de Movimientos</span>}
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="/inventario/transferencias" 
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                <i className="fas fa-exchange-alt"></i>
                {isOpen && <span> Transferencias</span>}
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
