// src/components/ventas/MarcasAdmin.jsx
import React, { useState } from 'react';
import { toast } from 'react-toastify'; // Importar solo 'toast'
import './MarcasAdmin.css';

const MarcasAdmin = ({ API_URL, token, marcas, fetchMarcas }) => {
  const [editMarcaId, setEditMarcaId] = useState(null);
  const [editMarcaNombre, setEditMarcaNombre] = useState('');

  const handleGuardarEdicionMarca = async (id) => {
    if (!editMarcaNombre.trim()) {
      toast.error('El nombre de la marca no puede estar vacío.');
      return;
    }

    try {
      const response = await fetch(`${API_URL}marcas/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ nombre: editMarcaNombre }),
      });

      if (response.ok) {
        toast.success('Marca actualizada exitosamente.');
        setEditMarcaId(null);
        setEditMarcaNombre('');
        fetchMarcas();
      } else {
        const errorData = await response.json();
        toast.error(errorData.error || 'Error al actualizar la marca.');
      }
    } catch (error) {
      toast.error('Error al actualizar la marca.');
      console.error(error);
    }
  };

  const handleCancelarEdicionMarca = () => {
    setEditMarcaId(null);
    setEditMarcaNombre('');
  };

  const handleEliminarMarca = async (id) => {
    if (!window.confirm('¿Estás seguro de que deseas eliminar esta marca?')) return;

    try {
      const response = await fetch(`${API_URL}marcas/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        toast.success('Marca eliminada exitosamente.');
        fetchMarcas();
      } else {
        const errorData = await response.json();
        toast.error(errorData.error || 'Error al eliminar la marca.');
      }
    } catch (error) {
      toast.error('Error al eliminar la marca.');
      console.error('Error al eliminar la marca:', error);
    }
  };

  return (
    <div className="marcas-admin-container">
      <h3>Gestión de Marcas</h3>

      <table className="productos-admin-tabla-productos">
        <thead>
          <tr>
            <th>ID</th>
            <th>Nombre</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {marcas.map((marca) => (
            <tr key={marca.id}>
              <td>{marca.id}</td>
              <td>
                {editMarcaId === marca.id ? (
                  <input
                    type="text"
                    value={editMarcaNombre}
                    onChange={(e) => setEditMarcaNombre(e.target.value)}
                  />
                ) : (
                  marca.nombre
                )}
              </td>
              <td>
                {editMarcaId === marca.id ? (
                  <>
                    <button className="btn-guardar" onClick={() => handleGuardarEdicionMarca(marca.id)}>
                      Guardar
                    </button>
                    <button className="btn-cancelar" onClick={handleCancelarEdicionMarca}>
                      Cancelar
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="btn-editar"
                      onClick={() => {
                        setEditMarcaId(marca.id);
                        setEditMarcaNombre(marca.nombre);
                      }}
                    >
                      Editar
                    </button>
                    <button className="btn-eliminar" onClick={() => handleEliminarMarca(marca.id)}>
                      Eliminar
                    </button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MarcasAdmin;
