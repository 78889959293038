// src/components/ventas/InventarioLayout.js
import React from 'react';
import { Outlet } from 'react-router-dom'; // Importar Outlet
import Sidebar from './Sidebar';
import './InventarioLayout.css';

const InventarioLayout = () => {
  return (
    <div className="inventario-layout">
      <Sidebar />
      <div className="contenido">
        <Outlet /> {/* Usar Outlet en lugar de {children} */}
      </div>
    </div>
  );
};

export default InventarioLayout;
