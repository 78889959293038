// src/components/Sidebars/SidebarDerecho.jsx

import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

// Importaciones para Servicios
import {
  fetchServicios,
  createServicio,
  updateServicio,
  deleteServicio,
} from '../Servicios/serviciosService';
import ServicioForm from '../Servicios/ServicioForm';
import ServicioItem from '../Servicios/ServicioItem';

// Importaciones para Empleados
import {
  fetchEmpleados,
  createEmpleado,
  updateEmpleado,
  deleteEmpleado,
} from '../Servicios/empleadosService';
import EmpleadoForm from '../Servicios/EmpleadoForm';
import EmpleadoItem from '../Servicios/EmpleadoItem';

import './SidebarDerecho.css';

const SidebarDerecho = () => {
  // Estado para Servicios
  const [servicios, setServicios] = useState([]);
  const [modoEdicionServicio, setModoEdicionServicio] = useState(false);
  const [idServicioEdicion, setIdServicioEdicion] = useState(null);
  const [nombreServicio, setNombreServicio] = useState('');
  const [costo, setCosto] = useState(0);
  const [duracion, setDuracion] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [gastos, setGastos] = useState([]);
  const [manoDeObra, setManoDeObra] = useState(0);
  const [mostrarFormularioServicio, setMostrarFormularioServicio] = useState(false);

  // Estado para Empleados
  const [empleados, setEmpleados] = useState([]);
  const [modoEdicionEmpleado, setModoEdicionEmpleado] = useState(false);
  const [idEmpleadoEdicion, setIdEmpleadoEdicion] = useState(null);
  const [nombreEmpleado, setNombreEmpleado] = useState('');
  const [apellidoEmpleado, setApellidoEmpleado] = useState('');
  const [correoEmpleado, setCorreoEmpleado] = useState('');
  const [telefonoEmpleado, setTelefonoEmpleado] = useState('');
  const [mostrarFormularioEmpleado, setMostrarFormularioEmpleado] = useState(false);

  const [mostrarSidebar, setMostrarSidebar] = useState(true);

  const { logout } = useAuth();
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  // Función para manejar errores de autenticación
  const handleAuthError = useCallback(
    (error) => {
      if (error.message.includes('Unauthorized') || error.message.includes('403')) {
        alert('Tu sesión ha expirado o no tienes autorización. Inicia sesión nuevamente.');
        logout();
        navigate('/');
      } else {
        console.error('Error en la operación:', error);
      }
    },
    [logout, navigate]
  );

  // Funciones para cargar datos, envueltas en useCallback
  const loadServicios = useCallback(async () => {
    try {
      const data = await fetchServicios(token);
      setServicios(data);
    } catch (error) {
      handleAuthError(error);
    }
  }, [token, handleAuthError]);

  const loadEmpleados = useCallback(async () => {
    try {
      const data = await fetchEmpleados(token);
      setEmpleados(data);
    } catch (error) {
      handleAuthError(error);
    }
  }, [token, handleAuthError]);

  // useEffect para cargar servicios y empleados al montar el componente
  useEffect(() => {
    const fetchData = async () => {
      try {
        await loadServicios();
        await loadEmpleados();
      } catch (error) {
        console.error('Error al cargar datos:', error);
      }
    };
    fetchData();
  }, [loadServicios, loadEmpleados]);

  // Funciones para Servicios

  const calcularTotal = useCallback(() => {
    const totalGastos = gastos.reduce(
      (sum, gasto) => sum + parseFloat(gasto.costo || 0),
      0
    );
    return totalGastos + parseFloat(manoDeObra || 0);
  }, [gastos, manoDeObra]);

  const resetServicioForm = useCallback(() => {
    setModoEdicionServicio(false);
    setIdServicioEdicion(null);
    setNombreServicio('');
    setCosto(0);
    setDuracion('');
    setDescripcion('');
    setGastos([]);
    setManoDeObra(0);
    setMostrarFormularioServicio(false);
  }, []);

  const handleSubmitServicio = useCallback(
    async (e) => {
      e.preventDefault();

      const servicioData = {
        nombre_servicio: nombreServicio,
        costo: calcularTotal(),
        duracion: parseInt(duracion, 10),
        descripcion,
        desglose: gastos,
        mano_de_obra: manoDeObra ? parseFloat(manoDeObra) : 0,
      };

      try {
        if (modoEdicionServicio) {
          await updateServicio(token, idServicioEdicion, servicioData);
          alert('Servicio actualizado exitosamente.');
        } else {
          await createServicio(token, servicioData);
          alert('Servicio creado exitosamente.');
        }
        await loadServicios();
        resetServicioForm();
      } catch (error) {
        handleAuthError(error);
      }
    },
    [
      token,
      nombreServicio,
      duracion,
      descripcion,
      gastos,
      manoDeObra,
      modoEdicionServicio,
      idServicioEdicion,
      loadServicios,
      handleAuthError,
      calcularTotal,
      resetServicioForm,
    ]
  );

  const handleEditarServicio = useCallback((servicio) => {
    setModoEdicionServicio(true);
    setIdServicioEdicion(servicio.id);
    setNombreServicio(servicio.nombre_servicio);
    setDuracion(servicio.duracion ? servicio.duracion.toString() : '');
    setDescripcion(servicio.descripcion || '');
    setGastos(servicio.desglose || []);
    setManoDeObra(servicio.mano_de_obra || 0);
    setMostrarFormularioServicio(true);
  }, []);

  const handleDuplicarServicio = useCallback(
    async (servicio) => {
      const nuevoNombre = `${servicio.nombre_servicio} - Copia`;
      const servicioDuplicado = {
        ...servicio,
        id: undefined,
        nombre_servicio: nuevoNombre,
      };

      try {
        await createServicio(token, servicioDuplicado);
        await loadServicios();
        alert('Servicio duplicado exitosamente.');
      } catch (error) {
        handleAuthError(error);
      }
    },
    [token, loadServicios, handleAuthError]
  );

  const handleEliminarServicio = useCallback(
    async (id) => {
      if (window.confirm('¿Estás seguro de que deseas eliminar este servicio?')) {
        try {
          await deleteServicio(token, id);
          // Actualizar la lista de servicios localmente
          setServicios((prevServicios) =>
            prevServicios.filter((servicio) => servicio.id !== id)
          );
          alert('Servicio eliminado exitosamente');
        } catch (error) {
          handleAuthError(error);
        }
      }
    },
    [token, handleAuthError]
  );

  const toggleFormularioServicio = useCallback(() => {
    setMostrarFormularioServicio((prevState) => !prevState);
  }, []);

  // Funciones para Empleados

  const resetEmpleadoForm = useCallback(() => {
    setModoEdicionEmpleado(false);
    setIdEmpleadoEdicion(null);
    setNombreEmpleado('');
    setApellidoEmpleado('');
    setCorreoEmpleado('');
    setTelefonoEmpleado('');
    setMostrarFormularioEmpleado(false);
  }, []);

  const handleSubmitEmpleado = useCallback(
    async (e) => {
      e.preventDefault();
  
      const empleadoData = {
        nombre: nombreEmpleado,
        apellido: apellidoEmpleado,
        correo: correoEmpleado,
        telefono: telefonoEmpleado,
      };
  
      try {
        if (modoEdicionEmpleado) {
          await updateEmpleado(token, idEmpleadoEdicion, empleadoData);
          alert('Empleado actualizado exitosamente.');
        } else {
          await createEmpleado(token, empleadoData);
          alert('Empleado creado exitosamente.');
        }
        await loadEmpleados();
        resetEmpleadoForm();
      } catch (error) {
        handleAuthError(error);
      }
    },
    [
      token,
      nombreEmpleado,
      apellidoEmpleado,
      correoEmpleado,
      telefonoEmpleado,
      modoEdicionEmpleado,
      idEmpleadoEdicion,
      loadEmpleados,
      handleAuthError,
      resetEmpleadoForm,
    ]
  );
  

  const handleEditarEmpleado = useCallback((empleado) => {
    setModoEdicionEmpleado(true);
    setIdEmpleadoEdicion(empleado.id);
    setNombreEmpleado(empleado.nombre);
    setApellidoEmpleado(empleado.apellido);
    setCorreoEmpleado(empleado.correo);
    setTelefonoEmpleado(empleado.telefono || '');
    setMostrarFormularioEmpleado(true);
  }, []);

  const handleEliminarEmpleado = useCallback(
    async (id) => {
      if (window.confirm('¿Estás seguro de que deseas eliminar este empleado?')) {
        try {
          await deleteEmpleado(token, id);
          // Actualizar la lista de empleados localmente
          setEmpleados((prevEmpleados) =>
            prevEmpleados.filter((empleado) => empleado.id !== id)
          );
          alert('Empleado eliminado exitosamente');
        } catch (error) {
          handleAuthError(error);
        }
      }
    },
    [token, handleAuthError]
  );

  const toggleFormularioEmpleado = useCallback(() => {
    setMostrarFormularioEmpleado((prevState) => !prevState);
  }, []);

  const toggleSidebar = useCallback(() => {
    setMostrarSidebar((prevState) => !prevState);
  }, []);

  return (
    <>
      {/* Botón para alternar el sidebar */}
      <button
        className={`btn-toggle ${mostrarSidebar ? 'oculto' : ''}`}
        onClick={toggleSidebar}
      >
        {mostrarSidebar ? '<' : '>'}
      </button>

      {/* El sidebar */}
      <div className={`sidebar-derecho ${mostrarSidebar ? 'oculto' : ''}`}>
        <div className="sidebar-content">
          {/* Botones para Crear Servicio y Empleado */}
          <button className="crear-elemento" onClick={toggleFormularioServicio}>
            {mostrarFormularioServicio ? 'Ocultar Formulario Servicio' : 'Crear Servicio'}
          </button>

          {mostrarFormularioServicio && (
            <ServicioForm
              nombreServicio={nombreServicio}
              setNombreServicio={setNombreServicio}
              costo={costo}
              setCosto={setCosto}
              duracion={duracion}
              setDuracion={setDuracion}
              descripcion={descripcion}
              setDescripcion={setDescripcion}
              gastos={gastos}
              setGastos={setGastos}
              manoDeObra={manoDeObra}
              setManoDeObra={setManoDeObra}
              handleSubmitServicio={handleSubmitServicio}
              resetForm={resetServicioForm}
              calcularTotal={calcularTotal}
              modoEdicion={modoEdicionServicio}
            />
          )}

          <button className="crear-elemento" onClick={toggleFormularioEmpleado}>
            {mostrarFormularioEmpleado ? 'Ocultar Formulario Empleado' : 'Crear Empleado'}
          </button>

          {mostrarFormularioEmpleado && (
            <EmpleadoForm
              nombreEmpleado={nombreEmpleado}
              setNombreEmpleado={setNombreEmpleado}
              apellidoEmpleado={apellidoEmpleado}
              setApellidoEmpleado={setApellidoEmpleado}
              correoEmpleado={correoEmpleado}
              setCorreoEmpleado={setCorreoEmpleado}
              telefonoEmpleado={telefonoEmpleado}
              setTelefonoEmpleado={setTelefonoEmpleado}
              handleSubmitEmpleado={handleSubmitEmpleado}
              resetForm={resetEmpleadoForm}
              modoEdicion={modoEdicionEmpleado}
            />
          )}

          {/* Listado de Servicios */}
          <h3>Servicios Creados</h3>
          <ul className="lista-elementos">
            {servicios.length === 0 ? (
              <p>No hay servicios creados.</p>
            ) : (
              servicios.map((servicio) => (
                <ServicioItem
                  key={servicio.id}
                  servicio={servicio}
                  handleEditarServicio={() => handleEditarServicio(servicio)}
                  handleDuplicarServicio={() => handleDuplicarServicio(servicio)}
                  handleEliminarServicio={() => handleEliminarServicio(servicio.id)}
                />
              ))
            )}
          </ul>

          {/* Listado de Empleados */}
          <h3>Empleados</h3>
          <ul className="lista-elementos">
            {empleados.length === 0 ? (
              <p>No hay empleados registrados.</p>
            ) : (
              empleados.map((empleado) => (
                <EmpleadoItem
                  key={empleado.id}
                  empleado={empleado}
                  handleEditarEmpleado={() => handleEditarEmpleado(empleado)}
                  handleEliminarEmpleado={() => handleEliminarEmpleado(empleado.id)}
                />
              ))
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default SidebarDerecho;
