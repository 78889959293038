// src/components/DropdownWithCheckbox.jsx

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import './DropdownWithCheckbox.css'; // Asegúrate de tener los estilos adecuados

const DropdownWithCheckbox = ({
  label,
  options,
  selectedOptions,
  setSelectedOptions,
  isMultiSelect,
  readOnly = false, // Prop para modo de solo lectura
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Manejar clic fuera del dropdown para cerrarlo
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Manejar selección de opciones
  const handleOptionChange = (optionId) => {
    if (readOnly) return; // Evitar cambios si es solo lectura

    if (isMultiSelect) {
      if (selectedOptions.includes(optionId)) {
        setSelectedOptions(selectedOptions.filter(id => id !== optionId));
      } else {
        setSelectedOptions([...selectedOptions, optionId]);
      }
    } else {
      if (selectedOptions.includes(optionId)) {
        setSelectedOptions([]);
      } else {
        setSelectedOptions([optionId]);
      }
      setIsOpen(false); // Cerrar dropdown si es single-select
    }
  };

  // Obtener etiquetas de las opciones seleccionadas
  const getSelectedLabels = () => {
    const selected = options.filter(option => selectedOptions.includes(option.id));
    if (readOnly) {
      return ''; // En modo solo lectura, no mostrar etiquetas en el header
    }
    if (selected.length === 0) return 'Selecciona una opción';
    if (isMultiSelect) return selected.map(option => option.nombre).join(', ');
    return selected[0].nombre;
  };

  // Obtener el texto del header dependiendo del modo
  const getHeaderText = () => {
    if (readOnly) {
      return 'Ver Categorías';
    }
    return getSelectedLabels();
  };

  return (
    <div className="dropdown-container" ref={dropdownRef}>
      {label && <label className="dropdown-label">{label}:</label>}
      <div
        className="dropdown-header"
        onClick={() => !readOnly && setIsOpen(!isOpen)} // Permitir abrir solo si no es solo lectura
        role="button"
        aria-haspopup="true"
        aria-expanded={isOpen}
        tabIndex={0}
        onKeyPress={(e) => {
          if ((e.key === 'Enter' || e.key === ' ') && !readOnly) {
            setIsOpen(!isOpen);
          }
        }}
      >
        <span>{getHeaderText()}</span>
        <span className={`dropdown-arrow ${isOpen ? 'open' : ''}`}></span>
      </div>
      {isOpen && (
        <div className="dropdown-list" role="menu">
          {readOnly ? (
            options.length > 0 ? (
              options.map(option => (
                <div key={option.id} className="dropdown-item read-only">
                  {option.nombre}
                </div>
              ))
            ) : (
              <div className="dropdown-item read-only">Sin categorías</div>
            )
          ) : (
            options.map(option => (
              <label
                key={option.id}
                className="dropdown-item"
                role={isMultiSelect ? 'menuitemcheckbox' : 'menuitemradio'}
                aria-checked={selectedOptions.includes(option.id)}
              >
                <input
                  type={isMultiSelect ? 'checkbox' : 'radio'}
                  checked={selectedOptions.includes(option.id)}
                  onChange={() => handleOptionChange(option.id)}
                  tabIndex={-1}
                />
                <span>{option.nombre}</span>
              </label>
            ))
          )}
        </div>
      )}
    </div>
  );
};

// Definición de las props y sus tipos
DropdownWithCheckbox.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired, // ID de la categoría
      nombre: PropTypes.string.isRequired, // Nombre de la categoría
    })
  ).isRequired,
  selectedOptions: PropTypes.arrayOf(
    PropTypes.number.isRequired
  ).isRequired,
  setSelectedOptions: PropTypes.func.isRequired, // Función para manejar cambios
  isMultiSelect: PropTypes.bool.isRequired, // Indica si es multi-select
  readOnly: PropTypes.bool, // Indica si es solo lectura
};

export default DropdownWithCheckbox;
