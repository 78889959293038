import React, { useState, useEffect } from 'react';

const InventarioDashboard = () => {
  const [estadisticas, setEstadisticas] = useState({
    totalProductos: 0,
    valorInventario: 0,
    productosBajoStock: 0,
  });

  useEffect(() => {
    // Simulación de cálculo de estadísticas
    const productos = [
      { id: 1, nombre: 'Shampoo Reparador', precio: 15.00, stock: 20 },
      { id: 2, nombre: 'Acondicionador Hidratante', precio: 12.00, stock: 3 },
      { id: 3, nombre: 'Mascarilla Nutritiva', precio: 20.00, stock: 10 },
    ];

    const totalProductos = productos.length;
    const valorInventario = productos.reduce((total, producto) => total + producto.precio * producto.stock, 0);
    const productosBajoStock = productos.filter(producto => producto.stock < 5).length;

    setEstadisticas({
      totalProductos,
      valorInventario,
      productosBajoStock,
    });
  }, []);

  return (
    <div className="inventario-dashboard">
      <h2 className="mb-4">Panel de Control de Inventario</h2>
      <div className="row text-center">
        <div className="col-md-4">
          <div className="card shadow-sm p-3 mb-4">
            <h5>Total de Productos</h5>
            <p className="display-4">{estadisticas.totalProductos}</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card shadow-sm p-3 mb-4">
            <h5>Valor Total de Inventario</h5>
            <p className="display-4">${estadisticas.valorInventario.toFixed(2)}</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card shadow-sm p-3 mb-4">
            <h5>Productos Bajo Stock</h5>
            <p className="display-4">{estadisticas.productosBajoStock}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventarioDashboard;
