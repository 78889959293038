// src/components/ventas/CategoriasAdmin.jsx
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import './CategoriasAdmin.css';

const CategoriasAdmin = ({ API_URL, token, categorias, fetchCategorias }) => {
  const [editCategoriaId, setEditCategoriaId] = useState(null);
  const [editCategoriaNombre, setEditCategoriaNombre] = useState('');

  const handleGuardarEdicionCategoria = async (id) => {
    if (!editCategoriaNombre.trim()) {
      toast.error('El nombre de la categoría no puede estar vacío.');
      return;
    }

    try {
      const response = await fetch(`${API_URL}categorias/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ nombre: editCategoriaNombre }),
      });

      if (response.ok) {
        toast.success('Categoría actualizada exitosamente.');
        setEditCategoriaId(null);
        setEditCategoriaNombre('');
        fetchCategorias();
      } else {
        const errorData = await response.json();
        toast.error(errorData.error || 'Error al actualizar la categoría.');
      }
    } catch (error) {
      toast.error('Error al actualizar la categoría.');
      console.error(error);
    }
  };

  const handleCancelarEdicionCategoria = () => {
    setEditCategoriaId(null);
    setEditCategoriaNombre('');
  };

  const handleEliminarCategoria = async (id) => {
    if (!window.confirm('¿Estás seguro de que deseas eliminar esta categoría?')) return;

    try {
      const response = await fetch(`${API_URL}categorias/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        toast.success('Categoría eliminada exitosamente.');
        fetchCategorias();
      } else {
        const errorData = await response.json();
        toast.error(errorData.error || 'Error al eliminar la categoría.');
      }
    } catch (error) {
      toast.error('Error al eliminar la categoría.');
      console.error('Error al eliminar la categoría:', error);
    }
  };

  return (
    <div className="categorias-admin-container">
      <h3>Gestión de Categorías</h3>

      <table className="productos-admin-tabla-productos">
        <thead>
          <tr>
            <th>ID</th>
            <th>Nombre</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {categorias.map((categoria) => (
            <tr key={categoria.id}>
              <td>{categoria.id}</td>
              <td>
                {editCategoriaId === categoria.id ? (
                  <input
                    type="text"
                    value={editCategoriaNombre}
                    onChange={(e) => setEditCategoriaNombre(e.target.value)}
                  />
                ) : (
                  categoria.nombre
                )}
              </td>
              <td>
                {editCategoriaId === categoria.id ? (
                  <>
                    <button className="btn-guardar" onClick={() => handleGuardarEdicionCategoria(categoria.id)}>
                      Guardar
                    </button>
                    <button className="btn-cancelar" onClick={handleCancelarEdicionCategoria}>
                      Cancelar
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="btn-editar"
                      onClick={() => {
                        setEditCategoriaId(categoria.id);
                        setEditCategoriaNombre(categoria.nombre);
                      }}
                    >
                      Editar
                    </button>
                    <button className="btn-eliminar" onClick={() => handleEliminarCategoria(categoria.id)}>
                      Eliminar
                    </button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CategoriasAdmin;
