// src/components/Servicios/ServicioItem.js

import React, { useState } from 'react';
import './ServicioItem.css';

const ServicioItem = ({
  servicio,
  handleEditarServicio,
  handleDuplicarServicio,
  handleEliminarServicio,
}) => {
  const [mostrarDetalles, setMostrarDetalles] = useState(false);

  const toggleDetalles = () => {
    setMostrarDetalles(!mostrarDetalles);
  };

  // Asegurarse de que los valores numéricos son números y formatearlos
  const costoTotal = parseFloat(servicio.costo) || 0;
  const manoDeObra = parseFloat(servicio.mano_de_obra) || 0;
  const duracion = parseInt(servicio.duracion, 10) || 0;

  // Confirmar antes de eliminar
  const confirmarEliminar = () => {
    const confirmado = window.confirm(
      `¿Estás seguro de que deseas eliminar el servicio "${servicio.nombre_servicio}"?`
    );
    if (confirmado) {
      handleEliminarServicio();
    }
  };

  // Confirmar antes de duplicar
  const confirmarDuplicar = () => {
    const confirmado = window.confirm(
      `¿Quieres duplicar el servicio "${servicio.nombre_servicio}"?`
    );
    if (confirmado) {
      handleDuplicarServicio();
    }
  };

  return (
    <li className="servicio-item">
      <div onClick={toggleDetalles} className="servicio-header">
        <h4>{servicio.nombre_servicio}</h4>
        <p>Total: ${costoTotal.toLocaleString('es-CL')}</p>
      </div>
      {mostrarDetalles && (
        <div className="detalles-servicio">
          <p>
            <strong>Descripción:</strong> {servicio.descripcion}
          </p>
          <p>
            <strong>Duración:</strong> {duracion} minutos
          </p>
          <p>
            <strong>Mano de Obra:</strong> ${manoDeObra.toLocaleString('es-CL')}
          </p>
          <h5>Materiales y Otros Gastos:</h5>
          {Array.isArray(servicio.desglose) && servicio.desglose.length > 0 ? (
            <ul className="gastos-lista">
              {servicio.desglose.map((gasto, index) => {
                const costoGasto = parseFloat(gasto.costo) || 0;
                return (
                  <li key={index}>
                    {gasto.nombre}: ${costoGasto.toLocaleString('es-CL')}
                  </li>
                );
              })}
            </ul>
          ) : (
            <p>No hay gastos adicionales.</p>
          )}
        </div>
      )}
      <div className="servicio-item-buttons">
        <button className="btn btn-editar" onClick={handleEditarServicio}>
          Editar
        </button>
        <button className="btn btn-duplicar" onClick={confirmarDuplicar}>
          Duplicar
        </button>
        <button className="btn btn-eliminar" onClick={confirmarEliminar}>
          Eliminar
        </button>
      </div>
    </li>
  );
};

export default ServicioItem;
