// src/components/AgregarCategoria/AgregarCategoria.jsx

import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import debounce from 'lodash.debounce';
import './AgregarCategoria.css'; // Asegúrate de tener este archivo CSS

const AgregarCategoria = ({ API_URL, token, fetchCategorias }) => {
  const [nuevaCategoria, setNuevaCategoria] = useState({ nombre: '' });
  const [exists, setExists] = useState(false);
  const [checking, setChecking] = useState(false);

  // Función para verificar si la categoría ya existe (case-insensitive)
  const verificarExistenciaCategoria = useCallback(
    debounce(async (nombre) => {
      if (!nombre.trim()) {
        setExists(false);
        return;
      }
  
      setChecking(true);
      try {
        const response = await fetch(`${API_URL}categorias`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (response.ok) {
          const data = await response.json();
          
          // Verifica si el nombre ya existe ignorando mayúsculas y minúsculas
          const nombreLower = nombre.trim().toLowerCase();
          const existeCategoria = data.some(
            (categoria) => categoria.nombre.trim().toLowerCase() === nombreLower
          );
  
          if (existeCategoria) {
            setExists(true);
            toast.warn(`La categoría "${nombre}" ya existe.`);
          } else {
            setExists(false);
          }
        } else {
          console.error('Error al verificar la existencia de la categoría.');
        }
      } catch (error) {
        console.error('Error al verificar la existencia de la categoría:', error);
      } finally {
        setChecking(false);
      }
    }, 500),
    [API_URL, token]
  );
  

  useEffect(() => {
    verificarExistenciaCategoria(nuevaCategoria.nombre);
    // Limpiar el debounce al desmontar el componente
    return () => verificarExistenciaCategoria.cancel();
  }, [nuevaCategoria.nombre, verificarExistenciaCategoria]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNuevaCategoria({ ...nuevaCategoria, [name]: value });
  };

  const handleAgregarCategoria = async () => {
    const { nombre } = nuevaCategoria;

    // Validación básica
    if (!nombre.trim()) {
      toast.error('El nombre de la categoría es obligatorio.');
      return;
    }

    if (exists) {
      toast.error(`La categoría "${nombre}" ya existe.`);
      return;
    }

    try {
      const response = await fetch(`${API_URL}categorias`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(nuevaCategoria),
      });

      const data = await response.json();

      if (response.ok) {
        setNuevaCategoria({ nombre: '' });
        fetchCategorias(); // Actualizar la lista de categorías
        toast.success('Categoría agregada exitosamente.');
      } else {
        if (data.errors) {
          const mensajes = data.errors.map((err) => err.msg).join('. ');
          toast.error(mensajes);
        } else {
          toast.error(data.error || 'Error al agregar la categoría.');
        }
      }
    } catch (error) {
      toast.error('Error al agregar la categoría.');
      console.error('Error al agregar la categoría:', error);
    }
  };

  return (
    <div className="agregar-categoria-formulario">
      <h3>Agregar Nueva Categoría</h3>
      <input
        type="text"
        placeholder="Nombre de la categoría"
        name="nombre"
        value={nuevaCategoria.nombre}
        onChange={handleInputChange}
      />
      <button onClick={handleAgregarCategoria} disabled={exists || checking}>
        {checking ? 'Verificando...' : 'Agregar Categoría'}
      </button>
    </div>
  );
};

export default AgregarCategoria;
