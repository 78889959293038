import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const ResetPasswordForm = () => {
  const { token } = useParams();  // Obtiene el token de la URL
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  // Definir la URL del backend
  const backendUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001/api/';

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Las contraseñas no coinciden.');
      return;
    }

    try {
      const response = await fetch(`${backendUrl}auth/reset-password/${token}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ newPassword: password }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || 'Error al restablecer la contraseña.');
      }

      setSuccess(true);
      alert('Contraseña restablecida con éxito.');
      navigate('/'); // Redirige al usuario a la página de inicio
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div>
      <h2>Restablecer Contraseña</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {success ? (
        <p>Contraseña restablecida con éxito. Serás redirigido a la página de inicio.</p>
      ) : (
        <form onSubmit={handleSubmit}>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Nueva contraseña"
            required
          />
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirmar nueva contraseña"
            required
          />
          <button type="submit">Restablecer Contraseña</button>
        </form>
      )}
    </div>
  );
};

export default ResetPasswordForm;
